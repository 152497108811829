import get from 'lodash/get';
import clamp from 'lodash/clamp';

import dom from '../../wrapper/DomWrapper';

import modifiers from './modifiers';
import {
  adjustToDevice, DEVICE_TYPES, getDeviceRatio, GRID_SIZE,
} from '../../../device';

const MIN_ITEMS_PER_ROW = 1;
const MAX_ITEMS_PER_ROW = 6;
const ITEMS_FOR_SIZE = [0, 1, 1, 2, 2, 2, 3, 3, 4, 5, 5, 6, 6];
export const GRID_LIMITS = {
  grid: {
    minItemsPerRow: 1,
    maxItemsPerRow: 3,
    minTotalRows: 1,
    maxTotalRows: 2,
    itemsForSize: ITEMS_FOR_SIZE,
  },
  list: {
    itemsCount: 5,
  },
  minSpacing: 10,
  maxSpacing: 30,
};

export const getModifier = (type, modifier) => get(modifiers, [type, modifier], (item) => item);

export const toDom = (html) => {
  const div = dom.createElement('div');
  dom.addHtml(div, html);
  return div.firstChild;
};

export const getHeaderHeight = () => {
  const header = dom.getElement('.main__header.header_fixed');

  if (!header) return 0;

  return header.clientHeight;
};

export const getMaxItemsPerRow = (
  itemSize,
  defaultMaxValue,
  defaultItemsForSize,
) => {
  const finalDefaultItemsForSize = defaultItemsForSize || ITEMS_FOR_SIZE;

  return (
    finalDefaultItemsForSize[itemSize] || (defaultMaxValue || MAX_ITEMS_PER_ROW)
  );
};

export const getItemsPerRow = (
  itemSize,
  grid,
  device,
  minDefaultValue,
  maxDefaultValue,
  itemsForSize,
) => {
  const adjustSize = adjustToDevice(itemSize, device);
  const deviceRatio = getDeviceRatio(device, DEVICE_TYPES);
  const maxItemsPerRow = getMaxItemsPerRow(
    itemSize,
    maxDefaultValue || MAX_ITEMS_PER_ROW,
    itemsForSize || ITEMS_FOR_SIZE,
  );

  return clamp(
    Math.ceil((adjustSize * grid.items_per_row * deviceRatio) / GRID_SIZE),
    minDefaultValue || MIN_ITEMS_PER_ROW,
    maxItemsPerRow,
  );
};

export const actualProductPerRow = (
  device,
  itemSize,
  grid,
) => {
  const { minItemsPerRow, maxItemsPerRow, itemsForSize } = GRID_LIMITS;

  return getItemsPerRow(
    itemSize,
    grid,
    device,
    minItemsPerRow,
    maxItemsPerRow,
    itemsForSize,
  );
};

export const getLimit = (
  itemSize,
  grid,
  device,
) => {
  // eslint-disable-next-line camelcase
  const { itemsCount, items_per_row, total_rows } = grid;
  if (itemsCount) return itemsCount;

  return (
    clamp(
      // eslint-disable-next-line camelcase
      Math.ceil((items_per_row / GRID_SIZE) * itemSize),
      MIN_ITEMS_PER_ROW,
      getItemsPerRow(
        itemSize,
        grid,
        device,
        MIN_ITEMS_PER_ROW,
        MAX_ITEMS_PER_ROW,
        ITEMS_FOR_SIZE,
      ),
      // eslint-disable-next-line camelcase
    ) * total_rows
  );
};
