import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-cycle
import {
  getHeaderOffset,
  getSectionId,
  setUrlHash,
  getTopStickyOffset,
} from './index';
import dom from '../../../wrapper/DomWrapper';
import animateScroll from '../../../helpers/animateScroll';

export default (anchors) => {
  if (isEmpty(anchors)) return;

  anchors.forEach((anchor) => {
    const sectionId = getSectionId(anchor.hash);
    const elSection = dom.getElement(sectionId);

    if (!elSection) return;

    anchor.setAttribute('href', sectionId);

    setTimeout(() => {
      dom.on(anchor, 'click', (e) => {
        const endPosition = elSection.offsetTop;
        const headerOffset = getHeaderOffset();
        const topStickyOffset = getTopStickyOffset();
        e.preventDefault();
        animateScroll(endPosition - headerOffset - topStickyOffset);
        setUrlHash(sectionId);
      });
    }, 0);
  });
};
