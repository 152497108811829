export const FACEBOOK_PAGE_SELECTOR = '.fb-page';

export const FACEBOOK_IFRAME_CLASS = 'fb_iframe_widget';
export const FACEBOOK_IFRAME_FLUID_CLASS = 'fb_iframe_widget_fluid';
export const FACEBOOK_WIDGET_WIDTH_ATTR = 'data-width';
export const FACEBOOK_RENDER_STATUS_ATTR = 'fb-xfbml-state';
export const FACEBOOK_ON_RENDER_PROCESS_STATUS = 'parsed';

export const DOM_CHECKING_TIMEOUT = 0;
export const MIN_RESOLUTION = 240;
export const DEFAULT_WIDGET_WIDTH = 500;
