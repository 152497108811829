import isEmpty from 'lodash/isEmpty';
import dom from '../../../wrapper/DomWrapper';

export default () => {
  const links = dom.getCollection('a');

  if (isEmpty(links)) {
    return {
      currentPageAnchors: [],
      externalPageAnchors: [],
    };
  }

  return [...links].reduce((acc, curr) => {
    const href = curr.getAttribute('href');

    if (!href) return acc;

    if (href.startsWith('#')) {
      return {
        ...acc,
        currentPageAnchors: [...acc.currentPageAnchors, curr],
      };
    }

    if (href.indexOf('#') > -1) {
      return {
        ...acc,
        externalPageAnchors: [...acc.externalPageAnchors, curr],
      };
    }

    return acc;
  }, {
    currentPageAnchors: [],
    externalPageAnchors: [],
  });
};
