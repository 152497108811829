import { getContrastRatio, lightness } from '../color';
import dom from '../../wrapper/DomWrapper';
import {
  ACCEPTABLE_RATIO,
  BODY_CLASSNAME,
  BUTTONS_VARS,
  CONTRAST_CHECK_VARS,
  THEME_VARS,
} from './constants';

/**
 * Function for set color classes for body.
 */
export const setBodyColorClasses = () => {
  const bodyClasses = Object.entries({ ...BUTTONS_VARS, ...THEME_VARS }).reduce(
    (result, [colorName, colorVar]) => {
      const isColorLight = lightness(colorVar);
      const lightnessClasses = {
        [`${colorName}Light`]: isColorLight,
        [`${colorName}Dark`]: !isColorLight,
      };
      const contrastClasses = CONTRAST_CHECK_VARS[colorName]
        ? {
          [`${colorName}InputContrast`]: getContrastRatio(colorVar, CONTRAST_CHECK_VARS[colorName])
          < ACCEPTABLE_RATIO,
        }
        : {};

      return {
        ...result,
        ...lightnessClasses,
        ...contrastClasses,
      };
    },
    {},
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const className in bodyClasses) {
    if (Object.prototype.hasOwnProperty.call(bodyClasses, className) && bodyClasses[className]) {
      dom.addClass(dom.document.body, BODY_CLASSNAME[className]);
    }
  }
};

/**
 * Function for set 'has-scroll' if body has scroll.
 */
export const setBodyHasScroll = () => {
  const className = 'has-scroll';
  const elBody = dom.document.body;
  const withScroll = dom.window.innerHeight < elBody.offsetHeight;
  const hasClass = dom.hasClass(elBody, className);

  if (withScroll && !hasClass) {
    dom.addClass(elBody, className);
  }
  if (!withScroll && hasClass) {
    dom.removeClass(elBody, className);
  }
};
