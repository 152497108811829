import isUndefined from 'lodash/isUndefined';
import dom from '../wrapper/DomWrapper';

/**
 * Function for open new window.
 * @param {string} url
 * @param {string} title
 * @param {number | string} w
 * @param {number | string} h
 */
export default (url, title, w, h) => {
  const dualScreenLeft = !isUndefined(dom.window.screenLeft)
    ? dom.window.screenLeft : dom.window.screen.left;
  const dualScreenTop = !isUndefined(dom.window.screenTop)
    ? dom.window.screenTop : dom.window.screen.top;
  let width;

  if (dom.window.innerWidth) {
    width = dom.window.innerWidth;
  } else if (dom.document.documentElement.clientWidth) {
    width = dom.document.documentElement.clientWidth;
  } else {
    width = dom.window.screen.width;
  }

  let height;

  if (dom.window.innerHeight) {
    height = dom.window.innerHeight;
  } else if (dom.document.documentElement.clientHeight) {
    height = dom.document.documentElement.clientHeight;
  } else {
    height = dom.window.screen.height;
  }

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = dom.window.open(
    url,
    title,
    `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`,
  );

  // Puts focus on the newWindow
  if (dom.window.focus && newWindow) {
    newWindow.focus();
  }
};
