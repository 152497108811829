import dom from '../../wrapper/DomWrapper';

import {
  COOKIE_TOOLBAR_SETTINGS,
  COOKIE_TOOLBAR_ACCEPT,
  COOKIE_LIGHT_BG_COLOR,
} from './constants';
import { checkIsContrasted } from '../../helpers/color';

class CookieNotice {
  constructor(selector, manager) {
    this.selector = selector;
    this.elCookieNotice = dom.getElement(selector);
    this.manager = manager;
  }

  init = () => {
    if (!this.elCookieNotice) return;

    // eslint-disable-next-line max-len
    this.elCookieBannerSettingsButton = dom.getElement(COOKIE_TOOLBAR_SETTINGS, this.elCookieNotice);
    this.elCookieBannerAcceptButton = dom.getElement(COOKIE_TOOLBAR_ACCEPT, this.elCookieNotice);
    this.setTheme();
  };

  show = () => {
    dom.show(this.elCookieNotice);

    dom.on(this.elCookieBannerSettingsButton, 'click', this.handleCookieModal);
    dom.on(this.elCookieBannerAcceptButton, 'click', this.acceptCookie);
  }

  hide = () => {
    dom.hide(this.elCookieNotice);

    this.elCookieBannerSettingsButton.removeEventListener('click', this.handleCookieModal);
    this.elCookieBannerAcceptButton.removeEventListener('click', this.acceptCookie);
  }

  checkIsEnabled = () => this.elCookieNotice.getAttribute('data-active') === 'true';

  handleCookieModal = (event) => {
    this.manager.showModal(event);
  }

  setTheme = () => {
    const isDarkTheme = dom.hasClass(this.elCookieNotice, '_dark');

    if (isDarkTheme) return;

    const primaryColor = this.elCookieNotice
      .getAttribute('data-primary');
    const secondaryColor = this.elCookieNotice
      .getAttribute('data-secondary');

    const isPrimaryContrasted = checkIsContrasted(primaryColor, COOKIE_LIGHT_BG_COLOR);
    const isSecondaryContrasted = checkIsContrasted(secondaryColor, COOKIE_LIGHT_BG_COLOR);

    if (isPrimaryContrasted) {
      dom.addClass(this.elCookieNotice, '_primary');
      return;
    }

    if (isSecondaryContrasted) {
      dom.addClass(this.elCookieNotice, '_secondary');
      return;
    }

    dom.addClass(this.elCookieNotice, '_fixed-colors');
  }

  acceptCookie = () => {
    this.hide();
    this.manager.accept();
  }
}

export default CookieNotice;
