import isOutOfStock from './isOutOfStok';
import isBackOrder from './isBackOrder';

const isAvailable = (
  // eslint-disable-next-line camelcase
  { unlimited, quantity, allow_backorder },
) => !isOutOfStock({ unlimited, quantity }) || isBackOrder(
  { unlimited, quantity, allow_backorder },
);

export default isAvailable;
