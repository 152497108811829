import isEmpty from 'lodash/isEmpty';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';
import SharingButtonWidget from './SharingButtonWidget';

class SharingButtons extends WidgetWrapper {
  init = () => {
    const elShareButtonWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elShareButtonWidgetList)) return;

    elShareButtonWidgetList.forEach((element) => {
      const widget = new SharingButtonWidget(element);

      widget.init();
      setTimeout(() => {
        widget.resize();
        widget.split();
      });

      subscribeToDebouncedResizeChanging(element, widget.resize);
      subscribeToDebouncedResizeChanging(widget, widget.split);
    });
  };
}

export default SharingButtons;
