import isEmpty from 'lodash/isEmpty';

import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import dom from '../../../wrapper/DomWrapper';

import SocialButton from './SocialButton';

class SocialButtons extends WidgetWrapper {
  init = () => {
    const elSocialButtonsWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elSocialButtonsWidgetList)) return;

    elSocialButtonsWidgetList.forEach((element) => {
      const widget = new SocialButton(element);
      widget.init();
    });
  };
}

export default SocialButtons;
