import dom from '../../../wrapper/DomWrapper';

export default (selector) => {
  if (!selector) return;

  const elMainHeader = dom.getElement('.main__header', selector);
  const { borderTopWidth, borderBottomWidth } = elMainHeader.style;

  dom.document.documentElement.style.setProperty('--header_main_borderTopWidth', borderTopWidth);
  dom.document.documentElement.style.setProperty('--header_main_borderBottomWidth', borderBottomWidth);
};
