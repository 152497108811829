import dom from '../../../wrapper/DomWrapper';

export default () => {
  const elHeader = dom.getElement('.main__header');

  if (!elHeader) return 0;

  const isHeaderFixed = dom.hasClass(elHeader, 'header_fixed');

  if (!isHeaderFixed) return 0;

  return dom.getElementHeight(elHeader);
};
