import get from 'lodash/get';
import dom from '../wrapper/DomWrapper';

/**
 * Function for get data from __PRELOADED_STATE__
 * @param {Array|string} path
 * @param {*} def - default value
 * @return {*}
 */
// eslint-disable-next-line no-underscore-dangle
const getStateValue = (path, def = null) => get(dom.window.__PRELOADED_STATE__, path, def);

export default getStateValue;
