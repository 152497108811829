import dom from '../../wrapper/DomWrapper';

class TwitterPost {
  constructor(selector, settings) {
    this.selector = selector;
    this.settings = settings;
  }

  init() {
    const {
      tweetId, cards, theme, conversation,
    } = this.settings;
    const elTweetPost = dom.createElement('div');

    dom.addHtml(elTweetPost, `
      <blockquote 
        ${theme && `data-theme = ${theme}`}
        ${cards && `data-cards = ${cards}`}
        ${conversation && `data-conversation = ${conversation}`}
        class="twitter-tweet" 
        data-lang="en"
      >
        <a href="https://twitter.com/Twitter/status/${tweetId}?ref_src=twsrc%5Etfw"/>
      </blockquote>
    `);

    this.selector.appendChild(elTweetPost);
  }
}

export default TwitterPost;
