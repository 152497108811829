import isFunction from 'lodash/isFunction';
import DOM from '../../wrapper/DomWrapper';

const convertToText = (response) => response.text();

const convertToDom = (html) => new DOM.window.DOMParser()
  .parseFromString(html, 'text/xml')
  .querySelector('svg');

const preload = (preloader) => (dom) => (isFunction(preloader) ? preloader(dom) : dom);

const cleanPath = (isStatic, path) => (isStatic && path.substr(0, 2) === '//'
  ? path.substr(1)
  : path);

const writeSVG = (path, preloader, isStatic = false) => DOM.window
  .fetch(cleanPath(isStatic, path))
  .then(convertToText)
  .then(convertToDom)
  .then(preload(preloader));

export default writeSVG;
