import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import get from 'lodash/get';

import formatPrice from './formatPrice';

import { DEFAULT_CURRENCY_PREFIX } from '../constants';

const getPriceWithCurrency = (price, currencyFormatOptions) => {
  const currencyPrefix = get(currencyFormatOptions, 'currencyPrefix', DEFAULT_CURRENCY_PREFIX);

  if (!isString(price) && !isNumber(price)) return '';

  return `${currencyPrefix}${formatPrice(price, currencyFormatOptions)}`;
};

export default getPriceWithCurrency;
