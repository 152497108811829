import memoize from 'lodash/memoize';
import get from 'lodash/get';

import getStateValue from '../../../helpers/getStateValue';

export default memoize(async () => {
  const geoIpService = getStateValue(['apiUrls', 'geoipService']);

  if (!geoIpService) return new Promise((resolve) => resolve(null));

  const geoDataResponse = await fetch(`${geoIpService}/geoip`);
  if (!get(geoDataResponse, ['ok'])) return new Promise((resolve) => resolve(null));

  return geoDataResponse.json();
});
