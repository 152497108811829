import isString from 'lodash/isString';

import { GET_CATALOG_PAGE_REGEX } from '../constants';

const isCatalogPage = (href, storeUrl) => {
  if (!isString(href)) return false;

  return GET_CATALOG_PAGE_REGEX(storeUrl).test(href);
};

export default isCatalogPage;
