import isEmpty from 'lodash/isEmpty';
import dom from '../../wrapper/DomWrapper';

class CookieModalLink {
  constructor(selector, manager) {
    this.selector = selector;
    this.elLinks = dom.getCollection(this.selector);
    this.manager = manager;
  }

  init = () => {
    if (isEmpty(this.elLinks)) return;

    this.elLinks.forEach((el) => {
      dom.on(el, 'click', this.manager.showModal);
    });
  };
}

export default CookieModalLink;
