import dom from '../../../wrapper/DomWrapper';

const getSupportedInputTypes = () => {
  const supported = {
    date: false,
    number: false,
    time: false,
    datetime: false,
    'datetime-local': false,
    month: false,
    week: false,
  };
  const tester = dom.createElement('input');

  // eslint-disable-next-line no-restricted-syntax
  for (const type in supported) {
    // Do nothing - IE throws, FF/Chrome just ignores
    if (Object.prototype.hasOwnProperty.call(supported, type)) {
      try {
        tester.type = type;
        // eslint-disable-next-line no-empty
      } catch (err) {} finally {
        supported[type] = tester.type === type;
      }
    }
  }

  return supported;
};

const supported = getSupportedInputTypes();

export default {
  date: supported.date,
  time: supported.time,
  datetime: supported.datetime,
  number: supported.number,
  'datetime-local': supported['datetime-local'],
  month: supported.month,
  week: supported.week,
};
