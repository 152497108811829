import get from 'lodash/get';
import dom from '../../wrapper/DomWrapper';
import CookieAnalyticsManager from '../CookieBannerManager/CookieAnalyticsManager';
import uuid from '../../helpers/uuid';

import { getScreenResolution, postAnalyticApiRequest, postNavigatorRequest } from './utils';
import { LOCAL, LEAVE_WEBSITE_ACTION, ENTER_WEBSITE_ACTION } from './constants';

class PlatformAnalytics {
  constructor(projectId) {
    this.analyticsManager = new CookieAnalyticsManager();
    this.payload = {
      transactionId: uuid(),
      action: ENTER_WEBSITE_ACTION,
      cookie: this.analyticsManager.getCookies(),
      projectId,
      screenResolution: getScreenResolution(),
      path: dom.window.location.pathname,
    };
  }

  init = () => {
    this.insertAnalytic()
      .then(this.handleAnalytic)
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  }

  insertAnalytic = (payload = this.payload) => {
    const { action } = payload;

    if (action === LEAVE_WEBSITE_ACTION) return postNavigatorRequest(payload);

    return postAnalyticApiRequest(payload);
  }

  handleUnload = () => {
    const isAnalyticsEnabled = !!this.analyticsManager.getInitialValue();

    if (!isAnalyticsEnabled) return;

    this.insertAnalytic({
      ...this.payload,
      action: LEAVE_WEBSITE_ACTION,
    });
  }

  handleAnalytic = (response) => {
    const data = get(response, 'data');
    dom.on(dom.window, 'unload', this.handleUnload);

    // TODO check testing case with data (backend sends no data)
    if (!data) return;

    const analyticsData = Object.keys(data);

    analyticsData.forEach((key) => {
      const {
        value, maxAge, domain, ...options
      } = data[key];
      const maxAgeOptions = maxAge ? { 'max-age': maxAge } : {};
      const domainOptions = domain ? { domain: domain.indexOf(LOCAL) >= 0 ? LOCAL : domain } : {};
      const analyticsOptions = {
        ...options,
        ...maxAgeOptions,
        ...domainOptions,
      };

      this.analyticsManager.setCookies(key, value, analyticsOptions);
    });
  };
}

export default PlatformAnalytics;
