function decodeReducer(params, param) {
  const [key, value] = param.split('=');
  const staticParams = params;

  staticParams[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

  return staticParams;
}

export default (query = '') => {
  const initialState = {};

  return query.indexOf('?') >= 0
    ? query
      .slice(query.indexOf('?') + 1, query.length)
      .split('&')
      .reduce(decodeReducer, initialState)
    : initialState;
};
