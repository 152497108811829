import dom from '../../wrapper/DomWrapper';
import getStateValue from '../../helpers/getStateValue';

const attachErrorHandler = (photo, { errorClassTarget, errorClass }) => {
  if (!errorClass || !errorClassTarget) return;

  const elImg = dom.getElement('img', photo);

  if (!elImg) return;

  const handler = () => {
    elImg.src = getStateValue('staticData.noSrc');
    elImg.removeEventListener('error', handler);

    dom.addClass(dom.getElement(`.${errorClassTarget}`, photo), errorClass);
  };

  dom.on(elImg, 'error', handler);
};

export default attachErrorHandler;
