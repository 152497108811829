import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import debounce from 'lodash/debounce';
import Observer from './index';
import dom from '../wrapper/DomWrapper';

import { DEBOUNCE_WAIT, RESIZE_OBSERVER_NAME } from './constants';

export function initResizeObserver() {
  const currentWindowSize = Observer.get(RESIZE_OBSERVER_NAME);

  if (!currentWindowSize) return;

  Observer.get(RESIZE_OBSERVER_NAME).set({ size: dom.window.innerWidth });
}

export function createResizeObserver() {
  Observer.create(RESIZE_OBSERVER_NAME).set({
    size: dom.window.innerWidth,
  });
}

/**
 * @param {(HTMLElement|Element)} item
 * @param {function} callback
 */
export function subscribeToResizeChanging(item, callback) {
  if (!isFunction(callback)) return;

  Observer.subscribeTo(RESIZE_OBSERVER_NAME, item, {
    key: 'size',
    action: (value) => callback(value),
  });
}

/**
 * @param {(HTMLElement|Element)} item
 * @param {function} callback
 * @param {number} wait
 */
export function subscribeToDebouncedResizeChanging(item, callback, wait) {
  if (!isFunction(callback)) return;

  Observer.subscribeTo(RESIZE_OBSERVER_NAME, item, {
    key: 'size',
    action: debounce((value) => callback(value), isNumber(wait) ? wait : DEBOUNCE_WAIT),
  });
}
