import { getDeviceType } from '../../helpers/browser';
import { DEVICES } from '../../../device';
import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import dom from '../../wrapper/DomWrapper';
import Pagination from './Pagination';

class NumericPagination extends Pagination {
  pagesToShow = 5;

  constructor(options, container, renderer) {
    super(options, container, renderer);

    this.options = options;
    this.renderer = renderer;
    this.container = container;
  }

  init = () => {
    const { limit } = this.state;
    this.fireEvent(Pagination.events.onInit, { offset: 0, limit });
    subscribeToDeviceChanging(this, () => this.render());
  };

  getPageElement = (index, selectedPage) => ({
    page: index,
    title: index + 1,
    selected: selectedPage === index,
  });

  getPages = (pageCount, selected) => {
    const pageRangeDisplayed = getDeviceType() === DEVICES.PHONE ? 2 : 4;
    const marginPagesDisplayed = 0;
    const pages = [];

    if (pageCount === 0) {
      pages.push(this.getPageElement(0, 0));
    } else if (pageCount <= pageRangeDisplayed) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < pageCount; index++) {
        pages.push(this.getPageElement(index, selected));
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;

      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected;
        rightSide = pageRangeDisplayed - leftSide;
      }

      const createPageView = (index) => this.getPageElement(index, selected);

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < pageCount; index++) {
        const page = index + 1;

        if (page <= marginPagesDisplayed) {
          pages.push(createPageView(index));
          // eslint-disable-next-line no-continue
          continue;
        }

        if (page > pageCount - marginPagesDisplayed) {
          pages.push(createPageView(index));
          // eslint-disable-next-line no-continue
          continue;
        }

        if (index >= selected - leftSide && index <= selected + rightSide) {
          pages.push(createPageView(index));
        }
      }
    }

    return pages;
  };

  getTemplateData = ({ offset, total }) => {
    const { limit } = this.state;
    const currentPage = Math.ceil(offset / limit);
    const pageCount = Math.ceil(total / limit);
    const prevPage = currentPage > 0 ? currentPage - 1 : false;
    const nextPage = currentPage + 1 < pageCount ? currentPage + 1 : false;
    const pages = this.getPages(pageCount, currentPage);
    const prevDots = pages[0].page > 0;
    const nextDots = pages[pages.length - 1].page !== pageCount - 1;
    const itemFrom = currentPage * limit + 1;
    let itemTo = currentPage * limit + limit;
    if (itemTo > total) itemTo = total;

    return {
      itemFrom,
      itemTo,
      prevPage,
      prevDots,
      pages,
      nextDots,
      nextPage,
      total,
      visible: pageCount > 1,
      currentPage,
      pageCount,
    };
  };

  attachListeners = (pager) => {
    const pages = dom.getCollection('[data-page-num]', pager);

    if (pages) {
      pages.forEach((page) => {
        dom.on(page, 'click', () => {
          const { pageNum } = page.dataset;
          if (!pageNum) return;

          this.setPage(pageNum);
        });
      });
    }

    return pager;
  };
}

export default NumericPagination;
