import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-cycle
import { getSectionId } from './index';

export default (anchors) => {
  if (isEmpty(anchors)) return;

  anchors.forEach((anchor) => {
    const sectionId = getSectionId(anchor.hash);

    if (sectionId === anchor.hash) return;

    const [link] = anchor.href.split('#');
    anchor.setAttribute('href', link + sectionId);
  });
};
