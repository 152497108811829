// eslint-disable-next-line import/no-cycle
import {
  checkIsContainerEnough,
  sumWidth,
} from './index';

const splitToRowsEvenly = (arrayForSplit, rows, container, divider = 2) => {
  const arr = arrayForSplit;
  let rowCount = divider;
  const halfArrLength = Math.ceil(arr.length / rowCount);
  const newArr = arr.slice(arr.length - halfArrLength);
  const newArrWidth = sumWidth(newArr);

  if (!checkIsContainerEnough(newArrWidth, container)) {
    splitToRowsEvenly(arrayForSplit, rows, container, rowCount + 1);
  } else {
    rows.push(halfArrLength);

    let restArrLength = arr.length - halfArrLength;
    const newArray = new Array(rowCount - 1);

    if (restArrLength < halfArrLength) {
      rows.push(restArrLength);
      return;
    }

    newArray.fill(0).forEach(() => {
      rowCount -= 1;

      const half = Math.ceil(restArrLength / rowCount);

      rows.unshift(half);
      restArrLength -= half;
    });
  }
};

export default splitToRowsEvenly;
