import get from 'lodash/get';
import dom from '../../../wrapper/DomWrapper';
import { isCssVar, matchOnlyKey } from './index';
import extractVar from './extractVar';
import browser from '../../browser';
import { CSS_VAR_REGEX } from '../constants';

/**
 * @param {string} name
 * @param {string} oldCssVar
 * @return {string}
 */
function recursiveVarFind(name, oldCssVar) {
  let cssVar;

  // This logic for IE
  // ToDO: IE

  if (browser.isIe()) {
    const cssVars = get(dom.window, ['cssVarPoly', 'varsStorage', 'varsList'], {});
    cssVar = cssVars[name];
  } else {
    cssVar = dom.getCssVar(name);
  }

  let cssVarName = !cssVar ? name : extractVar(cssVar);

  // This logic for IE
  // ToDO: IE

  if (oldCssVar && browser.isIe()) {
    const key = matchOnlyKey(oldCssVar, CSS_VAR_REGEX);
    cssVarName = oldCssVar.replace(key, cssVarName);
  }

  return cssVar && isCssVar(cssVarName) ? recursiveVarFind(cssVarName, cssVar) : cssVarName;
}

export default recursiveVarFind;
