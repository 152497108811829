import color from 'color';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import getRoot from './utils/getRoot';

import { ACCECTABLE_CONRAST_RATIO } from './constants';

/**
 * Function return a valid color code.
 * Use only for dynamic
 * @param {string | number[]} value - source code like #FFF or rgb(50,10,255)
 * or [60, 90, 160] or var(--variable) or rgba(var(--variable), 0.5)
 * @returns {string} valid color code: rgba(var(--variable), 0.5) -> rgba(210, 60, 90, 0.5)
 */
export function toValidColor(value) {
  try {
    return color(isString(value) ? getRoot(value) : value);
  } catch (e) {
    // It is possible that function for a variable
    // might be defined in the collection but
    // there is no such variable defined for the template.
    return color('rgba(0,0,0,0)');
  }
}

/**
 * Function return is rgb color light
 * Use only for dynamic
 * @param {number[]} rgbArray
 * @returns {boolean}
 */
function isRgbLight(rgbArray) {
  if (!isArray(rgbArray)) return false;

  const [r, g, b, alpha] = rgbArray;

  if ((alpha && alpha < 0.5) || alpha === 0) return true; // transparent

  const rgbParam = (r * 299 + g * 587 + b * 114) / 1000;

  return rgbParam > 240;
}

/**
 * Function return true or false value in depend of average lightness of set color
 * ( true if set color is light )
 * Use only for dynamic
 * @param {string | number[]} rgb
 * @returns {boolean}
 */
export function lightness(rgb) {
  const rgbArray = toValidColor(rgb).array();

  return isRgbLight(rgbArray);
}

/**
 * Measure the relative luminance of color
 * {@link https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef | Formula to calculate it}
 * @param  {number[]} colorArray - array with length of 3,
 * that represent color in rgb like [R, G, B]
 * @returns {number}
 */
function getRelativeLuminance(colorArray) {
  if (!isArray(colorArray)) return 0;

  const [R, G, B] = colorArray
    .map((value) => value / 255)
    .map((value) => (value <= 0.03928 ? value / 12.92 : ((value + 0.055) / 1.055) ** 2.4));

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

/**
 * Get contrast ratio according to recommendations
 * Use only for dynamic
 * described on w3c {@link https://www.w3.org/TR/WCAG20-TECHS/G145.html|}.
 * {@link https://contrast-ratio.com/ | Example of usage such approach}
 * @param {string|number[]} color1 - color to compare with
 * @param {string|number[]} color2 - color to compare with
 * @returns {number} ratio between two colors
 */
export function getContrastRatio(color1, color2) {
  const color1Arr = toValidColor(color1).color;
  const color2Arr = toValidColor(color2).color;

  const l1 = getRelativeLuminance(color1Arr);
  const l2 = getRelativeLuminance(color2Arr);

  const lightest = Math.max(l1, l2);
  const darkest = Math.min(l1, l2);

  return (lightest + 0.05) / (darkest + 0.05);
}

/**
 * @param {string} colorString - color (any type)
 * @returns {boolean}
 */
export function isLight(colorString) {
  return toValidColor(colorString).isLight();
}

/**
 * Check is colors contrasted
 * @param {string|number[]} color1 - color to compare with
 * @param {string|number[]} color2 - color to compare with
 * @returns {boolean}
 */
export function checkIsContrasted(color1, color2) {
  if (!color1 || !color2) return false;

  const contrastRatio = getContrastRatio(color1, color2);

  return contrastRatio >= ACCECTABLE_CONRAST_RATIO;
}
