import {
  galleryErrorHandler,
  logoErrorHandler,
  sectionBgErrorHandler,
  slideBgErrorHandler,
} from './ErrorHandlers';

const imageErrorHandler = () => {
  galleryErrorHandler();
  logoErrorHandler();
  sectionBgErrorHandler();
  slideBgErrorHandler();
};

export default imageErrorHandler;
