import isEmpty from 'lodash/isEmpty';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import TwitterFactory from './Factory';

class Twitter extends WidgetWrapper {
  init = () => {
    this.elTwitters = dom.getCollection(this.selector);

    if (isEmpty(this.elTwitters)) return;

    const twitterComponent = new TwitterFactory();

    for (let i = 0; i < this.elTwitters.length; i += 1) {
      const selector = this.elTwitters[i];
      const twitter = twitterComponent.init(selector);

      if (!twitter) return;

      twitter.init();
    }
  };
}

export default Twitter;
