import isEmpty from 'lodash/isEmpty';
import dom from '../../wrapper/DomWrapper';
import { getResellerData, getResellerId } from '../../helpers/reseller';

const initPremiumFeatures = async () => {
  const elFeatures = dom.getCollection('.js-premium');

  if (isEmpty(elFeatures)) return;

  const resellerId = getResellerId();
  let resellerData = {};

  try {
    resellerData = await getResellerData(resellerId);
  } finally {
    const { limitedFeaturesPublishedViewType: type } = resellerData;
    const isReserve = type === 'reserve';

    elFeatures.forEach((el) => {
      if (isReserve) {
        dom.addClass(el, '_premium__reserve');
        dom.removeClass(el, '_premium__hide');
      } else {
        dom.removeElement(el);
      }
    });
  }
};

export default initPremiumFeatures;
