import dom from '../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../wrapper/WidgetWrapper';

import ecommerce from '../../../ecommerce';

class StorefrontPage extends WidgetWrapper {
  init = async () => {
    const elStorefront = dom.getElement('.storefront-wrapper');
    dom.addClass(elStorefront, 'spinner');
    dom.updateStyle(elStorefront, { minHeight: `${Math.round(dom.window.innerHeight / 2)}px` });
    dom.window.xProductBrowser('id=storefront-page');
    await ecommerce.provider.initStorefront(elStorefront);
    dom.removeClass(elStorefront, 'spinner');
  };
}

export default StorefrontPage;
