export const INSTAGRAM_IMAGE_CLASS_NAME = 'instagram-item';
export const INSTAGRAM_IMAGE_CAPTION_CLASS_NAME = 'instagram-caption';
export const INSTAGRAM_IMAGE_LINK = (code) => (`https://www.instagram.com/p/${code}`);

export const INSTAGRAM_CAPTION_TEMPLATE = (caption = '') => (
  `<div class="${INSTAGRAM_IMAGE_CAPTION_CLASS_NAME}">${caption}</div>`
);
export const INSTAGRAM_IMAGE_TEMPLATE = (
  code,
  src,
  caption,
  targetBlank = true,
  showCaption = false,
  className = '',
) => (
  `<div class="_error-wrap">
    <a 
      class="${className}"
      href="${INSTAGRAM_IMAGE_LINK(code)}"
      target="${targetBlank ? '_blank' : '_self'}"
    >
      <img src="${src}" alt=""/>
    </a>
    ${showCaption ? INSTAGRAM_CAPTION_TEMPLATE(caption) : ''}
  </div>
`);
