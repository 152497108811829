import isEmpty from 'lodash/isEmpty';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import writeSVG from './writeSVG';
import getStateValue from '../../helpers/getStateValue';

class SVGLoader extends WidgetWrapper {
  constructor(selector) {
    super(selector);
    this.element = dom.getElement(selector);
    this.isStatic = getStateValue('staticData.isStaticPublished');
    this.icons = new Set(JSON.parse(this.element.dataset.icons));
    this.parent = dom.createElement('svg', { className: 'hidden' });
  }

  init = () => {
    this.loadAssetsIcons();
    this.loadProjectIcons();
    dom.document.body.insertBefore(this.parent, dom.document.body.firstChild);
    dom.removeElement(this.element);
  };

  svgPreload = (svg) => {
    this.icons.forEach((el) => {
      const icon = dom.getElement(`#${el}`, svg);

      if (!icon) return;

      this.icons.delete(el);
      this.parent.appendChild(icon);
    });
  }

  loadAssetsIcons() {
    ['global', 'templates', 'publish'].forEach((name) => {
      if (isEmpty(this.icons)) return;
      if (getStateValue('local')) {
        writeSVG(`/assets/svg/${name}.svg`, this.svgPreload, this.isStatic);
        return;
      }

      const assetsVersion = getStateValue('publishAssetsVersion');

      if (!assetsVersion) return;

      const path = `${!this.isStatic ? getStateValue('apiUrls.staticCdnService') : ''}/bundle/publish/${assetsVersion}/svg/${name}.svg`;
      writeSVG(path, this.svgPreload, this.isStatic);
    });
  }

  loadProjectIcons() {
    const iconsPath = getStateValue('iconsPath') || getStateValue('projectId');

    if (!iconsPath || isEmpty(this.icons)) return;
    if (this.isStatic) {
      writeSVG(`${getStateValue('staticData.staticDir')}/icons.svg`, this.svgPreload);
      return;
    }

    const imageApiService = getStateValue('apiUrls.imageApiService');

    if (!imageApiService) return;

    writeSVG(`${imageApiService}/projects/${iconsPath}/icons.svg`, this.svgPreload);
  }
}

export default SVGLoader;
