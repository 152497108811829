import isNil from 'lodash/isNil';
import dom from '../../../wrapper/DomWrapper';

function findClosestParentHref(target) {
  const parent = target.parentElement;

  if (!parent) return null;

  return parent.href ? parent.href : findClosestParentHref(parent);
}

export const sendMessage = (message, target = '*') => {
  const { parent } = dom.window;

  if (!parent) return;

  parent.postMessage(message, target);
};

export const getAnchorTargetObj = (target) => {
  const href = target.href || findClosestParentHref(target);
  let hash = href ? new URL(href).hash : null;

  if (isNil(hash)) return { offset: 0, hash };

  hash = hash.slice(1);
  const anchorTarget = dom.getElement(`#s-${hash}`);
  const offset = anchorTarget ? anchorTarget.offsetTop : 0;

  return { offset, hash };
};
