import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import TwitterList from './List';
import TwitterPost from './Post';

class TwitterFactory {
  init(twitter) {
    if (!twitter) return null;

    this.selector = twitter;
    this.settings = JSON.parse(get(this.selector, 'dataset.settings', {}));

    if (isEmpty(this.settings)) return null;

    const { view } = this.settings;

    switch (view) {
      case 'list':
        return new TwitterList(this.selector, this.settings);
      case 'post':
        return new TwitterPost(this.selector, this.settings);
      default:
        return null;
    }
  }
}

export default TwitterFactory;
