import dom from '../../wrapper/DomWrapper';

class FacebookComments {
  constructor(element) {
    this.elFacebookComment = element;
  }

  init() {
    const fbComment = dom.getElement('.fb-comments', this.elFacebookComment);
    const mutationObserver = new MutationObserver((diff) => {
      if (diff[0].target.getAttribute('fb-xfbml-state') === 'rendered') {
        dom.removeClass(this.elFacebookComment, 'spinner');
      }
    });

    mutationObserver.observe(fbComment, { attributes: true });
  }
}

export default FacebookComments;
