import isEmpty from 'lodash/isEmpty';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import LightBox from '../Lightbox';
import dom from '../../wrapper/DomWrapper';

import { LIGHT_BOX_SELECTOR } from './constants';

class Gallery extends WidgetWrapper {
  init = () => {
    const elGalleryWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elGalleryWidgetList)) return;

    this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);

    elGalleryWidgetList.forEach((gallery = {}) => {
      const { widgetId = null, settings } = gallery.dataset;

      if (widgetId && settings) {
        this.lightBox.galleryModalData[widgetId] = JSON.parse(settings);
        this.connectGalleryToLightBox(widgetId);
      }
    });
  };

  connectGalleryToLightBox = (galleryId) => {
    const {
      navButtonsAttributes,
      modalsParams,
    } = this.lightBox.getLightBoxAttributes();

    const galleryToConnect = galleryId
      ? { [galleryId]: this.lightBox.galleryModalData[galleryId] }
      : this.lightBox.galleryModalData;

    this.lightBox.addLightBoxToGalleryPhotos(
      galleryToConnect,
      navButtonsAttributes,
      modalsParams,
    );
  };
}

export default Gallery;
