export const PHONE = 'phone';
export const NOTE = 'note';
export const TABLET = 'tablet';
export const LAPTOP = 'laptop';
export const QUAD = 'quad';
export const DESKTOP = 'desktop';

/**
 phone: 320,
 tablet: 768,
 laptop: 1039,
 desktop: 1366,
 quad: 1920
 * @param element
 * @return {number}
 */

export const DEVICES = {
  PHONE,
  NOTE,
  TABLET,
  DESKTOP,
  LAPTOP,
  QUAD,
};

export const VERTICAL_INDEX = {
  [PHONE]: 0.3,
  [TABLET]: 0.5,
  [LAPTOP]: 0.5,
  [DESKTOP]: 1,
  [QUAD]: 1.2,
};

export const VERTICAL_INDEX_V2 = {
  [PHONE]: 0.6,
  [TABLET]: 0.6,
  [LAPTOP]: 0.75,
  [DESKTOP]: 1,
  [QUAD]: 1.25,
};

export const VERTICAL_INDEX_V3 = {
  ...VERTICAL_INDEX_V2,
  [PHONE]: 0.4,
};

export const VERTICAL_INDEX_V4 = {
  [PHONE]: 0.4,
  [TABLET]: 0.6,
  [LAPTOP]: 1,
  [DESKTOP]: 1,
  [QUAD]: 1,
};

export const MIN_POPOVER_OFFSET = 20;

export const DEVICE_SIZES = {
  [PHONE]: [0, 767],
  [TABLET]: [768, 1024],
  [LAPTOP]: [1025, 1366],
  [DESKTOP]: [1367, 1920],
  [QUAD]: [1921, 2560],
};

export const DEVICE_GRID = {
  4: {
    tablet: 6,
    mobile: 6,
    phone: 12,
  },
  3: {
    tablet: 6,
    mobile: 6,
    phone: 12,
  },
  2: {
    tablet: 3,
    mobile: 3,
    phone: 6,
  },
};

export const DEVICE_TYPES = [DEVICES.TABLET, DEVICES.PHONE];

export const GRID_SIZE = 12;

export const USE_THUMBNAIL_STATUS = {
  UNKNOWN: -1,
  DONT_USE: 0,
  DEFAULT_THUMBNAIL: 1,
};

export const getDeviceSizes = (note = false, fix = true) => {
  const sizes = { ...DEVICE_SIZES };

  if (note) {
    sizes[PHONE] = [0, 479];
    sizes[NOTE] = [480, 767];
  }

  if (fix) {
    sizes[TABLET] = [768, 1024];
    sizes[LAPTOP] = [1025, 1366];
  }

  return sizes;
};

/**
 * Get ratio applied to item width for device types
 * @param {String} device
 * @param {Array} deviceTypes
 * @returns {Number} Device coefficient for item width
 */
export const getDeviceRatio = (device, deviceTypes) => {
  const DEFAULT_RATIO = 1;
  return deviceTypes.includes(device) ? VERTICAL_INDEX[device] : DEFAULT_RATIO;
};

// eslint-disable-next-line no-nested-ternary
export const adjustToDevice = (itemSize, device) => (DEVICE_TYPES.includes(device)
  ? DEVICE_GRID[itemSize]
    ? DEVICE_GRID[itemSize][device]
    : GRID_SIZE
  : itemSize);
