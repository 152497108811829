class WidgetWrapper {
  constructor(selector) {
    this.selector = selector;
  }

  // eslint-disable-next-line class-methods-use-this
  init() {
    throw new Error();
  }
}

export default WidgetWrapper;
