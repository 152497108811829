import dom from '../../../wrapper/DomWrapper';
import animateScroll from '../../../helpers/animateScroll';
// eslint-disable-next-line import/no-cycle
import { getHeaderOffset } from './index';

export default () => {
  const { hash } = dom.window.location;

  if (!hash || !hash.startsWith('#s-')) return;

  const elSection = dom.getElement(hash);

  if (!elSection) return;

  setTimeout(() => {
    const endPosition = elSection.offsetTop;
    const headerOffset = getHeaderOffset();
    animateScroll(endPosition - headerOffset, dom.window.pageYOffset, 0);
  }, 0);
};
