import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

class EmbedCode extends WidgetWrapper {
  init = () => {
    this.elEmbed = dom.getElement(this.selector);

    if (!this.elEmbed) return;

    dom.on(dom.window, 'message', this.updateIframeHeight);
  };

  updateIframeHeight = (e) => {
    const { type, height, name } = e.data;
    const elIframe = dom.getElement(`iframe[name="${name}"]`);

    if (!elIframe) return;

    const parent = elIframe.parentNode;

    if (type === 'iframeResize') {
      dom.removeClass(parent, 'show_spinner');
      elIframe.setAttribute('height', height);
    }
  };
}

export default EmbedCode;
