import toString from 'lodash/toString';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/flow';

import { CSS_VAR_KEY_REGEX, CSS_VAR_REGEX } from '../constants';
import { matchOnlyKey, toRGB } from './index';

/**
 * Removes all spaces from string
 * @param {string} value
 * @return {string}
 */
const trim = (value) => {
  if (!isString(value)) return value;

  return value.replace(/\s/g, '');
};

const toCssRootValue = flow([trim, toRGB, toString]);

/**
 * @param {function} getter
 * @param {string} name (in callback)
 * @return {string}
 */
const createRootGetter = (getter) => (name) => {
  if (!isString(name)) return name;

  const key = matchOnlyKey(name, CSS_VAR_KEY_REGEX);
  const cssVar = matchOnlyKey(name, CSS_VAR_REGEX);
  const value = getter(key);

  if (!key || key === value) return name;

  if (cssVar && cssVar !== name) {
    if (isEmpty(value)) return name;

    return name.replace(cssVar, `${value}`);
  }

  return toCssRootValue(value);
};

export default createRootGetter;
