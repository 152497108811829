import isFunction from 'lodash/isFunction';
import Observer from './index';
import { getDeviceType } from '../helpers/browser';

import { DEVICE_OBSERVER_NAME } from './constants';

export function initDeviceObserver() {
  const currentDevice = Observer.get(DEVICE_OBSERVER_NAME);

  if (!currentDevice) return;

  const currentDeviceType = currentDevice.get(DEVICE_OBSERVER_NAME);
  const updatedDeviceType = getDeviceType();

  if (currentDeviceType === updatedDeviceType) return;

  Observer.get(DEVICE_OBSERVER_NAME).set({ type: updatedDeviceType });
}

export function createDeviceObserver() {
  Observer.create(DEVICE_OBSERVER_NAME).set({
    type: getDeviceType(),
  });
}

/**
 * @param {(HTMLElement|Element)} item
 * @param {function} callback
 */
export function subscribeToDeviceChanging(item, callback) {
  if (!isFunction(callback)) return;

  Observer.subscribeTo(DEVICE_OBSERVER_NAME, item, {
    key: 'type',
    action: (value) => callback(value),
  });
}
