export const SHARE_BUTTON_CLASS = '.share__btn';
export const SHARE_ROW_CLASS = '.share__row';
export const SHARE_TEXT_CLASS = '.share__text';
export const SHARE_EDIT_CLASS = '.share__edit';
export const SHARE_BOX_CLASS = '.share__box';
export const SHARE_ICON_CLASS = '.icon';

export const CENTER = '_center';
export const RIGHT = '_right';
export const COLLAPSED = '_collapsed';
export const COLLAPSED_ALL = '_collapsed-all';
export const SPACE_FACTOR = 1.88 / 1.364;

export const WINDOW_WIDTH = 500;
export const WINDOW_HEIGHT = 500;
export const MIN_ICON_WIDTH = 24;
