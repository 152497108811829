import isEmpty from 'lodash/isEmpty';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import LightBox from '../Lightbox/LightBox';
import dom from '../../wrapper/DomWrapper';

import { LIGHT_BOX_SELECTOR } from './constants';

class Photo extends WidgetWrapper {
  init = () => {
    const elPhotoWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elPhotoWidgetList)) return;

    this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);

    elPhotoWidgetList.forEach((photo = {}) => {
      const { widgetId = null, settings } = photo.dataset;

      if (widgetId && settings) {
        const setting = JSON.parse(settings);

        this.lightBox.photoModalData[setting.hash] = setting;
        this.connectPhotoToLightBox(widgetId);
      }
    });
  };

  connectPhotoToLightBox = (photoId) => {
    const { modalsParams } = this.lightBox.getLightBoxAttributes();

    const photoToConnect = photoId
      ? { [photoId]: this.lightBox.photoModalData[photoId] }
      : this.lightBox.photoModalData;

    this.lightBox.addLightBoxToPhotos(
      photoToConnect,
      modalsParams,
    );
  };
}

export default Photo;
