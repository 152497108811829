import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { isFullUrl } from './urlInfo';

import { FB } from '../../constants';
import {
  DEFAULT_IMAGE, FILE_DOWNLOAD_ATTR, NO_SRC, STATIC_MARKERS,
} from './constants';
import { getData } from '../globalData';

/**
 * Function for get home page url
 * @return {string}
 */
export function getHomePage() {
  const menu = getData('menu');

  if (!menu) return '';

  const items = menu.list.concat(menu.unlinked, menu.hidden || []);
  let homepage = items[0].tag;

  items.forEach((el) => {
    if (el.homepage) {
      homepage = el.tag;
    }
  });

  return homepage;
}

/**
 * Function for get related path
 * @param {string} link
 * @return {string}
 */
const getRelatedPath = (link) => {
  if (!link || !isString(link)) return link;

  const linkWithoutDoubleSlashes = link.replace(/([^:]\/)\/+/g, '$1');

  return linkWithoutDoubleSlashes[0] === '/'
    ? linkWithoutDoubleSlashes.substring(1)
    : linkWithoutDoubleSlashes;
};

/**
 * Function for create correct link with hash
 * @param {Array} menuItems
 * @param {string} hash
 * @param {string} anchor
 * @return {string}
 */
export function pageHashToLink(menuItems, hash, anchor) {
  const page = getData('page');
  const pageId = get(page, ['pageId']);
  let activeTag = null;

  const iterateMenu = (menu, path) => {
    if (Array.isArray(menu) && menu.length) {
      menu.forEach((menuItem) => {
        if (menuItem.hash === hash) {
          // eslint-disable-next-line no-nested-ternary
          activeTag = pageId === menuItem.hash
            ? ''
            : menuItem.homepage
              ? '/'
              : `${path}${menuItem.tag}`;
        } else if (menuItem.children) {
          iterateMenu(menuItem.children, `${path}${menuItem.tag}/`);
        }
      });
    } else if (menu && typeof menu === 'object') {
      Object.values(menu).forEach((item) => iterateMenu(item, path));
    }
  };

  if (pageId === hash) activeTag = '';
  else iterateMenu(menuItems, '/');

  const res = !activeTag && typeof activeTag !== 'string' ? hash : activeTag;
  return (res || '') + (anchor || '') || undefined;
}

/**
 * Function for create correct link with hash
 * @param {string} href
 * @param {string} anchor
 * @return {string}
 */
function getHref(href, anchor) {
  const menu = getData('menu');
  const allLinks = menu && (menu.list || []).concat(menu.unlinked, menu.hidden || []);
  const sectionAnchor = anchor && `#s-${anchor}`;

  return pageHashToLink(allLinks, href, sectionAnchor);
}

/**
 * Function for normalise facebook link
 * @param {string} href
 * @return {string}
 */
export function normalizeFBhref(href = '') {
  if (href && !href.includes('http')) {
    if (href.indexOf('facebook.com') === 0) {
      // eslint-disable-next-line no-param-reassign
      href = `https://${href}`;
    } else if (href.indexOf('fb.com') === 0) {
      // eslint-disable-next-line no-param-reassign
      href = href.replace('fb.com', FB);
    } else {
      // eslint-disable-next-line no-param-reassign
      href = `${FB}/${href}`;
    }
  }
  return href.slice(-1) === '/' ? href.slice(0, -1) : href;
}

/**
 * Function for filter digits only
 * @param {string} value
 * @return {string}
 */
export const getOnlyDigits = (value) => {
  if (!isString(value)) return '';

  return value.replace(/\D/g, '');
};

/**
 * Function for normalise link
 * @param {string} type
 * @param {string} href
 * @param {string} anchor
 * @return {string}
 */
export function getLink(type, href, anchor) {
  let link = (href || '').trim();

  if (link) {
    switch (type && type.toLowerCase()) {
      case 'anchor':
      case 'page':
        return getHref(link, anchor);
      case 'email':
        link = `mailto:${link}`;
        break;
      case 'phone':
        link = `tel:${link.includes('+') ? '+' : ''}${getOnlyDigits(link)}`;
        break;
      case 'url':
        if (!isFullUrl(link) && link !== '/') link = `http://${link}`;
        break;
      default:
        break;
    }
  }

  return link || undefined;
}

/**
 * Function for convert link to download link
 * @param {string} link
 * @return {string}
 */
export const convertLinkToDownloadType = (link) => {
  if (!link || !isString(link) || link.includes(FILE_DOWNLOAD_ATTR)) return link;

  return link + FILE_DOWNLOAD_ATTR;
};

/**
 * Function for extract filename
 * @param {string} link
 * @return {string}
 */
export const extractNameFromFileLink = (link) => {
  if (!link || !isString(link) || !link.length) return link;

  const fileName = link.split('/').pop();
  const downloadAttrIndex = fileName.indexOf(FILE_DOWNLOAD_ATTR);

  return downloadAttrIndex >= 0
    ? fileName.substring(0, downloadAttrIndex)
    : fileName;
};

/**
 * Function for check if static link
 * @param {string} link
 * @return {boolean}
 */
const checkStatic = (link) => {
  if (!link || !isString(link)) return false;

  const relatedPath = getRelatedPath(link);

  return STATIC_MARKERS.some((marker) => relatedPath.startsWith(marker));
};

/**
 * Function for check for external link
 * @param {string} link
 * @return {boolean}
 */
const checkExternal = (link) => {
  if (!link || !isString(link)) return false;

  return link.startsWith('http');
};

/**
 * Function for convert link to correct
 * @param {string} src
 * @param {Object} urlPrefixes
 * @param {Object} staticData
 * @return {string}
 */
export const convertSrcToCorrect = (src = '', urlPrefixes = {}, staticData = {}) => {
  if (!src || !isString(src)) return src;

  const { static: staticApi, fileStorageService } = urlPrefixes;
  const { isStaticPublished = false, staticDir = '' } = staticData;

  if (src === NO_SRC) return `${staticApi}/${DEFAULT_IMAGE}`;

  if (checkExternal(src)) return src;

  if (isStaticPublished) {
    const pureSrc = src.startsWith('/') ? src.substring(1) : src;
    const pureStaticDir = staticDir.startsWith('/') ? staticDir.substring(1) : staticDir;

    if (pureSrc.startsWith(pureStaticDir)) return `/${pureSrc}`;

    return `/${pureStaticDir}/${pureSrc}`;
  }

  if (isEmpty(urlPrefixes)) return src;

  if (checkStatic(src)) return `${staticApi}/${getRelatedPath(src)}`;

  return `${fileStorageService}/${getRelatedPath(src)}`;
};

/**
 * Function for get link props
 * @param {string} link
 * @param {string} linkType
 * @param {Object} urlPrefixes
 * @param {Object} staticData
 * @param {string} anchor
 * @return {Object}
 */
export const getLinkProps = (link, linkType, urlPrefixes = {}, staticData = {}, anchor) => {
  if (!link || !isString(link)) return { href: null };

  const href = getLink(linkType, link, anchor);

  if (isString(linkType) && linkType.toLowerCase() !== 'file') return { href };

  const fileLink = convertSrcToCorrect(href, urlPrefixes, staticData);

  return {
    href: convertLinkToDownloadType(fileLink),
    download: extractNameFromFileLink(fileLink),
  };
};
