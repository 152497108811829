import dom from '../wrapper/DomWrapper';

export const scrollToPosition = (offset) => {
  dom.window.scrollTo(0, offset);
};

export const easeInOutCubic = (t, b, c, d) => {
  // eslint-disable-next-line no-param-reassign
  const ts = (t /= d) * t;
  const tc = ts * t;

  return b + c * (-2 * tc + 3 * ts);
};

export const cancelAnimFrame = (() => (
  dom.window.cancelAnimationFrame
  || dom.window.webkitCancelRequestAnimationFrame
  || dom.window.mozCancelRequestAnimationFrame
  || dom.window.oCancelRequestAnimationFrame
  || dom.window.msCancelRequestAnimationFrame
  || clearTimeout
).bind(dom.window))();

// eslint-disable-next-line func-names
export const requestAnimFrame = (function () {
  return (
    dom.window.requestAnimationFrame
    || dom.window.webkitRequestAnimationFrame
    || dom.window.mozRequestAnimationFrame
    || dom.window.oRequestAnimationFrame
    || dom.window.msRequestAnimationFrame
    // eslint-disable-next-line func-names
    || function (callback) {
      dom.window.setTimeout(callback, 1000 / 60);
    }
  );
}());

export default (endPosition = 0, scrollY = dom.window.pageYOffset, duration = 400) => {
  const el = dom.window;
  const start = scrollY;
  const distance = endPosition - scrollY;

  let timeStart;
  let timeElapsed;
  let frameId;
  let initialFrameId;
  let cancelled;

  const handleUserScroll = () => {
    el.removeEventListener('wheel', handleUserScroll);
    el.removeEventListener('touchmove', handleUserScroll);
    cancelAnimFrame(frameId);
    cancelAnimFrame(initialFrameId);
    cancelled = true;
  };

  dom.on(el, 'wheel', handleUserScroll);
  dom.on(el, 'touchmove', handleUserScroll);

  const end = () => scrollToPosition(start + distance);

  const loop = (time) => {
    if (cancelled) return;

    timeElapsed = time - timeStart;
    const offset = easeInOutCubic(timeElapsed, start, distance, duration);
    scrollToPosition(offset);

    if (timeElapsed < duration) {
      frameId = requestAnimFrame(loop);
      return;
    }

    end();
  };

  initialFrameId = requestAnimFrame((time) => {
    timeStart = time;
    loop(time);
  });
};
