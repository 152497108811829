export const GO_TO_CHECKOUT = 'Go to Checkout';
export const ADD_TO_CART = 'Add to Cart';
export const OPEN_PRODUCT_PAGE = 'Open Product page';
export const BUTTON_ACTIONS = [GO_TO_CHECKOUT, ADD_TO_CART, OPEN_PRODUCT_PAGE];

export const PAGE_TYPE_CATALOG = 'ecommerce';
export const PAGE_TYPE_CART = 'ecommerceCart';
export const PAGE_TYPE_SHIPPING = 'ecommerceShipping';
export const PAGE_TYPE_PRODUCT = 'ecommerceProduct';

// Aspect ratio
export const PORTRAIT_0667 = 2 / 3;
export const PORTRAIT_075 = 3 / 4;
export const SQUARE_1 = 1;
export const LANDSCAPE_1333 = 4 / 3;
export const LANDSCAPE_15 = 3 / 2;

export const ASPECT_RATIO = {
  PORTRAIT_0667,
  PORTRAIT_075,
  SQUARE_1,
  LANDSCAPE_1333,
  LANDSCAPE_15,
};

export const PRICE_MODIFIER_TYPES = {
  ABSOLUTE: 'absolute',
  PERCENT: 'percent',
};
export const PRICE_MODIFIER_SIGNS = {
  POSITIVE: 1,
  NEGATIVE: -1,
  POSITIVE_ZERO: 0,
};

export const PRODUCTS = 'products';

export const PRODUCT_OPTION_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  SELECT: 'select',
};

export const VERTICAL_LOCK_OPTIONS = {
  axis: 'y',
  lockAxis: 'y',
  lockToContainerEdges: false,
  distance: 5,
};

export const DEFAULT_CURRENCY_PREFIX = '$';

export const ECWID = 'ecwid';
export const PROVIDERS = {
  ECWID,
};

export default {
  ASPECT_RATIO,
  BUTTON_ACTIONS,
  // ECOMMERCE_OPTIONS,
  PRODUCTS,
  PROVIDERS,
  VERTICAL_LOCK_OPTIONS,
};

export const SHOW = 'SHOW';
export const HIDE = 'HIDE';

export const STOREFRONT = {
  product_list_sku_behavior: HIDE,
  product_list_price_behavior: SHOW,
  product_list_buybutton_behavior: SHOW,
  product_list_image_aspect_ratio: 'SQUARE_1',
  product_list_show_additional_image_on_hover: true,
  product_list_show_frame: false,
  product_list_cell_spacing: parseFloat(25),
};

export const BACKGROUND_COLORS = [
  '#b5ebdc',
  '#f9d9e2',
  '#d4def4',
  '#d3f2ff',
  '#fee1d8',
  '#deecd3',
  '#ffefcf',
  '#fbced1',
  '#e7d7d3',
  '#caf0ed',
];

// Payment status
export const AWAITING_PAYMENT = 'AWAITING_PAYMENT';
export const PAID = 'PAID';

// Fulfillment status
export const AWAITING_PROCESSING = 'AWAITING_PROCESSING';
export const PROCESSING = 'PROCESSING';
export const WILL_NOT_DELIVER = 'WILL_NOT_DELIVER';
export const RETURNED = 'RETURNED';
export const SHIPPED = 'SHIPPED';
export const DELIVERED = 'DELIVERED';

// Errors
// export const INSUFFICENT_DATA = 'Insufficient data provided';

export const DEFAULT_PRODUCTS = { items: [] };
export const DEFAULT_PROFILE = {
  currencySetting: { prefix: '$' },
  setting: { storefront: STOREFRONT },
};
export const DEFAULT_FORMATS_AND_UNITS = {
  currencyDecimalSeparator: '.',
  currencyGroupSeparator: ' ',
};

export const STORE_URL = 'storeUrl';

export const DEFAULT_CATEGORY = {
  id: 'default',
  categoryId: 'default',
  name: 'se.es.products_category_default',
  description: '',
};

export const HANDLE_CLOSE_TYPES = {
  ESC: 'ESC',
};

/**
 * ATTENTION: Regex order make sens!!!
 */
export const URL_REGEX = {
  [PAGE_TYPE_CART]: (storeUrl) => new RegExp(`${storeUrl}(checkout|cart)`, 'i'),
  [PAGE_TYPE_PRODUCT]: (storeUrl) => new RegExp(`${storeUrl}\\S*-p[0-9]+$`, 'i'),
  [PAGE_TYPE_CATALOG]: (storeUrl) => new RegExp(`${storeUrl}/?`, 'i'),
};

export const CATALOGUE_THEMES = [
  {
    src: 'builder/catalog_preview/theme-01.png',
    activeSrc: 'builder/catalog_preview/theme-01.png',
    borderColor: 'var(--color_brand)',
    borderWidth: 0,
  },
  {
    src: 'builder/catalog_preview/theme-02.png',
    activeSrc: 'builder/catalog_preview/theme-02.png',
    itemBackgroundColor: '#fff',
  },
  {
    src: 'builder/catalog_preview/theme-04.png',
    activeSrc: 'builder/catalog_preview/theme-04.png',
    backgroundColor: 'var(--color_complementary)',
    boxShadowColor: 'var(--color_brand)',
    boxShadowWidth: 0,
  },
  {
    src: 'builder/catalog_preview/theme-05.png',
    activeSrc: 'builder/catalog_preview/theme-05.png',
    backgroundColor: 'var(--color_complementary)',
    itemBackgroundColor: '#fff',
  },
];

export const CATALOGUE_STYLES = [
  'backgroundColor',
  'itemBackgroundColor',
  'color',
  'boxShadowColor',
  'boxShadowWidth',
  'borderColor',
  'borderWidth',
];

export const catalogueThemeConfig = {
  themes: CATALOGUE_THEMES,
  styles: CATALOGUE_STYLES,
};

export const THEME_PARENT_STYLES = [
  [],
  [],
  ['backgroundColor', 'boxShadowColor', 'boxShadowWidth'],
  ['backgroundColor'],
];

export const THEME_ITEM_STYLES = [
  ['borderColor', 'borderWidth'],
  ['itemBackgroundColor'],
  // [],
  // [],
  ['backgroundColor'],
  ['itemBackgroundColor'],
];

export const NAME_OR_PHOTO = 'Name or Photo';
export const NAME = 'Name';
export const PHOTO = 'Photo';
export const PRODUCT = 'Product';
export const HOVER_TARGETS = {
  nameOrPhoto: NAME_OR_PHOTO,
  name: NAME,
  photo: PHOTO,
  product: PRODUCT,
};

export const HIGHLIGHT_BORDER = 'Highlight Border';
export const HIGHLIGHT_BACKGROUND = 'Highlight Background';
export const HIGHLIGHT_SHADOW = 'Highlight Shadow';
export const ZOOM_IN_PHOTO = 'Zoom in photo';
export const SHOW_ADDITIONAL_IMAGE = 'Show additional image';

export const HOVER_EFFECT_DEFAULT_OPTIONS = {
  hasEffect: true,
  hoverEffect: SHOW_ADDITIONAL_IMAGE,
  hoverTarget: PRODUCT,
};

export const PRODUCT_STYLES = ['backgroundColor', 'boxShadow'];

export const PRODUCT_THEMES = [
  {
    src: 'builder/product_preview/theme-1.png',
    activeSrc: 'builder/product_preview/theme-1.png',
  },
  {
    src: 'builder/product_preview/theme-2.png',
    activeSrc: 'builder/product_preview/theme-2.png',
    boxShadow: 'var(--color_brand)',
  },
  {
    src: 'builder/product_preview/theme-3.png',
    activeSrc: 'builder/product_preview/theme-3.png',
    backgroundColor: 'rgb(var(--preColor_complementary))',
  },
  {
    src: 'builder/product_preview/theme-4.png',
    activeSrc: 'builder/product_preview/theme-4.png',
    backgroundColor: 'rgb(var(--preColor_complementary))',
    boxShadow: 'var(--color_brand)',
  },
];

export const BUTTON_PADDING_VERTICAL = 12;
export const BUTTON_LINE_HEIGHT = 2.4;
export const PHOTO_MARGIN_BOTTOM = 5;
export const TEXT_MARGIN_BOTTOM = 2;
export const PRICE_MARGIN_BOTTOM = PHOTO_MARGIN_BOTTOM;
export const DISPLAY_ELEMENTS = [
  'photo',
  'name',
  'price',
  'description',
  'button',
];
export const DEFAULT_PRODUCT_ELEMENT = [
  'photo',
  'name',
  'description',
];
export const DEFAULT_GRID_SIZE = { grid: { items_per_row: 4, total_rows: 2 } };
