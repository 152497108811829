import get from 'lodash/get';

const getProductStatusLabel = (status, element) => {
  try {
    const STATUS_LABEL_TEXT = JSON.parse(element.dataset.widgetStatusLabel);
    return get(STATUS_LABEL_TEXT, [status], '');
  } catch (e) {
    return '';
  }
};

export default getProductStatusLabel;
