import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import { DEVICE_GRID, DEVICE_TYPES, GRID_SIZE } from './constants';

/**
 * @param {number} itemSize
 * @param {string} device
 * @return {number}
 */
export default function adjustToDevice(itemSize, device) {
  if (!isNumber(itemSize) || isNaN(itemSize)) return GRID_SIZE;

  if (itemSize > GRID_SIZE || itemSize <= 0) return GRID_SIZE;

  // eslint-disable-next-line no-nested-ternary
  return DEVICE_TYPES.indexOf(device) !== -1
    ? (DEVICE_GRID[itemSize]
      ? DEVICE_GRID[itemSize][device]
      : GRID_SIZE)
    : itemSize;
}
