/**
 * Function for redo input string to lowercase.
 * @param {string} input
 * @return {string}
 */
export const camelToKebab = (input) => input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

/**
 * Function for split string by dot and filter by length.
 * @param {string} str
 * @return {Array|null}
 */
export const splitStringWithDots = (str) => {
  if (typeof str !== 'string') return null;

  return str.split('.').filter((part) => !!part.length);
};

/**
 * Function for convert string to CamelCase.
 * @param {string} str
 * @return {string|null}
 */
export const convertUnderlinedToCamelcase = (str) => {
  if (typeof str !== 'string') return null;

  return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};
