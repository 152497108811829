import dom from '../../wrapper/DomWrapper';

import {
  DEFAULT_WIDGET_WIDTH,
  DOM_CHECKING_TIMEOUT,
  FACEBOOK_IFRAME_CLASS,
  FACEBOOK_IFRAME_FLUID_CLASS,
  FACEBOOK_ON_RENDER_PROCESS_STATUS,
  FACEBOOK_PAGE_SELECTOR,
  FACEBOOK_RENDER_STATUS_ATTR,
  FACEBOOK_WIDGET_WIDTH_ATTR,
  MIN_RESOLUTION,
} from './constants';
import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';

class FacebookPage {
  constructor(element) {
    this.elFacebookPage = element;
  }

  init = () => {
    if (!this.elFacebookPage) return;

    this.facebookPageContainer = dom.getElement(FACEBOOK_PAGE_SELECTOR, this.elFacebookPage);

    if (!this.facebookPageContainer) return;

    this.widgetWidthSettings = this.facebookPageContainer.getAttribute(FACEBOOK_WIDGET_WIDTH_ATTR)
      || DEFAULT_WIDGET_WIDTH;

    subscribeToDebouncedResizeChanging(this.elFacebookPage, this.resize);
  };

  resize = () => {
    const widgetWidth = this.elFacebookPage.clientWidth;

    setTimeout(() => {
      const pageWidth = this.facebookPageContainer.clientWidth;
      const shouldRerenderMobile = widgetWidth < this.widgetWidthSettings;
      const shouldRerenderDesktop = pageWidth < widgetWidth && pageWidth < this.widgetWidthSettings;
      const shouldRerender = (shouldRerenderMobile || shouldRerenderDesktop)
        && widgetWidth > MIN_RESOLUTION;
      const isAlreadyRendering = this.checkIsAlreadyRendering();

      if (shouldRerender && !isAlreadyRendering) this.rerender();
    }, DOM_CHECKING_TIMEOUT);
  };

  checkIsAlreadyRendering = () => {
    const renderStatus = this.facebookPageContainer.getAttribute(FACEBOOK_RENDER_STATUS_ATTR);

    return renderStatus === FACEBOOK_ON_RENDER_PROCESS_STATUS;
  };

  rerender = () => {
    const fbPageWidget = dom.window.FB;

    if (!this.facebookPageContainer || !fbPageWidget) return;

    dom.removeClass(this.facebookPageContainer, FACEBOOK_IFRAME_CLASS);
    dom.removeClass(this.facebookPageContainer, FACEBOOK_IFRAME_FLUID_CLASS);

    fbPageWidget.XFBML.parse();
  };
}

export default FacebookPage;
