import isEmpty from 'lodash/isEmpty';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import FacebookPage from './FacebookPage';
import FacebookComments from './FacebookComments';

class Facebook extends WidgetWrapper {
  init = () => {
    const elFacebookWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elFacebookWidgetList)) return;

    elFacebookWidgetList.forEach((element) => {
      if (!element) return;

      const { name } = element.dataset;

      let widget;

      switch (name) {
        case 'comments':
          widget = new FacebookComments(element);
          break;
        case 'page':
          widget = new FacebookPage(element);
          break;
        default:
          widget = new FacebookPage(element);
      }

      widget.init();
    });
  };
}

export default Facebook;
