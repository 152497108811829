import isAvailable from '../utils/isAvailable';
import isOutOfStock from '../utils/isOutOfStok';
import isOnSale from '../utils/isOnSale';
import isBackOrder from '../utils/isBackOrder';

class ProductModel {
  constructor() {
    this.id = null;

    this.name = '';

    this.title = '';

    this.description = '';

    this.quantity = 0;

    this.price = 0;

    this.priceFormatted = '';

    this.priceWithOptions = 0;

    this.priceWithOptionsFormatted = '';

    this.compareToPrice = 0;

    this.compareToPriceFormatted = '';

    this.compareToPriceWithOptions = 0;

    this.compareToPriceWithOptionsFormatted = '';

    this.discountFormatted = '';

    this.sku = null;

    this.options = {};

    this.category = '';

    this.limit = 100;

    this.unlimited = false;

    this.inStock = false;

    this.keyword = [];

    this.images = [];

    this.weight = 0;

    this.width = 0;

    this.height = 0;

    this.length = 0;
  }

  isAvailable = () => isAvailable(this);

  isOutOfStock = () => isOutOfStock(this);

  isOnSale = () => isOnSale(this);

  isBackorder = () => isBackOrder(this);

  cantAddToCartMore = (countInCart) => {
    const { unlimited, quantity } = this;

    if (unlimited) return false;

    return (!this.isOutOfStock() && countInCart && quantity <= countInCart);
  }
}

export default ProductModel;
