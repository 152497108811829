import isEmpty from 'lodash/isEmpty';
import browser from './browser';
import dom from '../wrapper/DomWrapper';
import { isCssVar } from './color/utils';
import { toValidColor } from './color';
import getRoot from './color/utils/getRoot';

// This logic for IE
// ToDO: IE

const ieChangeCssVar = () => {
  if (!browser.isIe()) return;

  const elWithVar = dom.getCollection('[data-style*="var(--"]');

  if (isEmpty(elWithVar)) return;

  elWithVar.forEach((el) => {
    const style = el.getAttribute('data-style');
    const objStyle = JSON.parse(style);

    const stylesToChange = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const param in objStyle) {
      if (Object.prototype.hasOwnProperty.call(objStyle, param) && isCssVar(objStyle[param])) {
        if (param === 'border' || param === 'boxShadow') {
          stylesToChange[param] = getRoot(objStyle[param]);
        } else {
          stylesToChange[param] = toValidColor(objStyle[param]);
        }
      }
    }

    dom.updateStyle(el, stylesToChange);
    el.removeAttribute('data-style');
  });
};

export default ieChangeCssVar;
