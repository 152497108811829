import defaultImage from '../utils';
import errorHandler from './errorHandler';

const logoErrorHandler = () => {
  const selectorName = '.logo__wrap';
  const onError = (elImage) => {
    // eslint-disable-next-line no-param-reassign
    elImage.src = defaultImage;
  };

  errorHandler(selectorName, onError);
};

export default logoErrorHandler;
