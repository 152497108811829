import dom from '../../wrapper/DomWrapper';

import ProductPage from '../../implementation/Ecommerce/Product';
import StorefrontPage from '../../implementation/Ecommerce/Storefront/Storefront';
import CatalogPage from '../../implementation/Ecommerce/CatalogPage';

import {
  isCartPage,
  isCatalogPage,
  isProductPage,
} from './utils';

import {
  CATALOG_PAGE_WRAPPER_ID,
  DEFAULT_STORE_URL,
  PAGE_TYPE,
  PRODUCT_PAGE_WRAPPER_ID,
  STOREFRONT_PAGE_WRAPPER_ID,
} from './constants';

import getProductId from './utils/getProductId';
import getStateValue from '../../helpers/getStateValue';

const WIDGET_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.PRODUCT_PAGE, new ProductPage(null)],
  [PAGE_TYPE.CART_PAGE, new StorefrontPage(null)],
  [PAGE_TYPE.CATALOG_PAGE, new CatalogPage(null)],
]);

const WRAPPER_ID_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.PRODUCT_PAGE, PRODUCT_PAGE_WRAPPER_ID],
  [PAGE_TYPE.CART_PAGE, STOREFRONT_PAGE_WRAPPER_ID],
  [PAGE_TYPE.CATALOG_PAGE, CATALOG_PAGE_WRAPPER_ID],
]);

class Router {
  static goToCatalogPage = () => {
    const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

    dom.window.location.pathname = `${storeUrl}/`;
  }

  static goToCartPage = () => {
    const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

    dom.window.location.pathname = `${storeUrl}/cart`;
  }

  static goToProductPage = (id, name) => {
    const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

    dom.window.location.pathname = `${storeUrl}/${name.replace(/[^a-zA-Z0-9]/g, '-')}-p${id}`;
  }

  constructor() {
    this.href = dom.window.location.href;
    this.type = this.getPageType();
  }

    init = () => {
      if (!WIDGET_BY_PAGE_TYPE.has(this.type)) return;

      const page = WIDGET_BY_PAGE_TYPE.get(this.type);

      this.showPage();
      page.init({
        id: this.getProductId(),
      });
    }

    getPageType = () => {
      const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

      if (isCartPage(this.href, storeUrl)) return PAGE_TYPE.CART_PAGE;

      if (isProductPage(this.href, storeUrl)) return PAGE_TYPE.PRODUCT_PAGE;

      if (isCatalogPage(this.href, storeUrl)) return PAGE_TYPE.CATALOG_PAGE;

      return null;
    }

    getProductId = () => {
      if (this.type !== PAGE_TYPE.PRODUCT_PAGE) return null;

      return getProductId(this.href);
    }

    // eslint-disable-next-line consistent-return
    showPage = () => {
      if (!WRAPPER_ID_BY_PAGE_TYPE.has(this.type)) return null;

      const currentPageWrapperId = WRAPPER_ID_BY_PAGE_TYPE.get(this.type);
      dom.show(dom.getElement(`#${currentPageWrapperId}`));
    }
}

export default Router;
