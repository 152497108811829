import 'intersection-observer';

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
    || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  // eslint-disable-next-line func-names
  Element.prototype.closest = function (s) {
    let el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

if (!window.navigator.sendBeacon) {
  // eslint-disable-next-line no-inner-declarations
  function sendBeacon(url, data) {
    const xhr = 'XMLHttpRequest' in window
      ? new XMLHttpRequest()
      : new ActiveXObject('Microsoft.XMLHTTP'); // eslint-disable-line no-undef
    xhr.open('POST', url, false);
    // xhr.withCredentials = true;
    xhr.setRequestHeader('Accept', '*/*');
    if (typeof data === 'string') {
      xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
      xhr.responseType = 'text/plain';
    } else if (Object.prototype.toString.call(data) === '[object Blob]') {
      if (data.type) {
        xhr.setRequestHeader('Content-Type', data.type);
      }
    }

    xhr.send(data);
    return true;
  }

  window.navigator.sendBeacon = sendBeacon;
}

(function (arr) {
  arr.forEach((item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('append')) {
      return;
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        // eslint-disable-next-line prefer-rest-params
        const argArr = Array.prototype.slice.call(arguments);
        const docFrag = document.createDocumentFragment();

        argArr.forEach((argItem) => {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.appendChild(docFrag);
      },
    });
  });
}([Element.prototype, Document.prototype, DocumentFragment.prototype]));

// eslint-disable-next-line import/prefer-default-export
export const doIEObjectFitPolyfill = (image) => {
  if ('objectFit' in document.documentElement.style !== false || !image) return;

  if (!image.getAttribute('data-object-fit')) return;

  // eslint-disable-next-line no-param-reassign
  (image.runtimeStyle || image.style).background = `url("${image.getAttribute('data-src') || image.src}") no-repeat 50%/${image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit')}`;
  // eslint-disable-next-line no-param-reassign
  image.src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${image.width}' height='${image.height}'%3E%3C/svg%3E`;
  image.removeAttribute('data-object-fit');
};
