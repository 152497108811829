export const BODY_CLASSNAME = {
  // Contrast ratio button colors
  primarySolidBackgroundLight: '_primary-light',
  secondarySolidBackgroundLight: '_secondary-light',
  primaryOutlineColorLight: '_primary_border-text-light',
  primaryOutlineBorderLight: '_primary_border-light',
  secondaryOutlineColorLight: '_secondary_border-text-light',
  secondaryOutlineBorderLight: '_secondary_border-light',
  primarySolidBackgroundDark: '_primary-dark',
  secondarySolidBackgroundDark: '_secondary-dark',
  primaryOutlineColorDark: '_primary_border-text-dark',
  primaryOutlineBorderDark: '_primary_border-dark',
  secondaryOutlineColorDark: '_secondary_border-text-dark',
  secondaryOutlineBorderDark: '_secondary_border-dark',
  // Contrast ratio theme colors
  primaryThemeDark: '_primary_theme-dark',
  primaryThemeLight: '_primary_theme-light',
  secondaryThemeDark: '_secondary_theme-dark',
  secondaryThemeLight: '_secondary_theme-light',
  textThemeDark: '_text_theme-dark',
  textThemeLight: '_text_theme-light',
  primaryBtnTooInputContrast: '_primary-btn_too-light',
  secondaryBtnTooInputContrast: '_secondary-btn_too-light',
  lightTextThemeDark: '_light-text_theme-dark',
  lightTextThemeLight: '_light-text_theme-light',
  textThemeInputContrast: '_theme_text_contrast',
  lightTextThemeInputContrast: '_theme_light-text_contrast',
};

export const BUTTONS_VARS = {
  primarySolidBackground: '--btn_primary_background',
  secondarySolidBackground: '--btn_secondary_background',
  primaryOutlineColor: '--btn_borderPrimary_color',
  primaryOutlineBorder: '--btn_borderPrimary_borderColor',
  secondaryOutlineColor: '--btn_borderSecondary_color',
  secondaryOutlineBorder: '--btn_borderSecondary_borderColor',
  primaryBtnToo: '--btn_primary_background',
  secondaryBtnToo: '--btn_secondary_background',
};

export const THEME_VARS = {
  primaryTheme: '--preColor_brand',
  secondaryTheme: '--preColor_altBrand',
  textTheme: '--preColor_text',
  lightTextTheme: '--preColor_textAlt',
};

export const CONTRAST_CHECK_VARS = {
  textTheme: '--input_background',
  lightTextTheme: '--input_background',
  primaryBtnToo: '--preColor_textAlt',
  secondaryBtnToo: '--preColor_text',
};

export const ACCEPTABLE_RATIO = 4.51;
