import ObserverClass from './observerClass';

class Observer {
  observers = {};

  /**
   * @param {string} name
   * @returns {boolean}
   */
  check(name) {
    return !!this.observers[name];
  }

  /**
   * @param {string} name
   * @returns {Object}
   */
  create(name) {
    this.observers[name] = new ObserverClass(name);

    return this.observers[name];
  }

  /**
   * @param {string} name
   * @returns {*}
   */
  get(name) {
    // eslint-disable-next-line no-console
    if (!this.check(name)) console.warn(`Observer with name ${name} is undefined`);

    return this.observers[name];
  }

  /**
   * @param {string} name
   * @param {HTMLElement} component
   * @param {Object}
   * @returns {Object}
   */
  subscribeTo(name, component, { action, key = null }) {
    const observer = this.get(name);

    if (observer) observer.subscribe(component, action, key);

    return observer;
  }
}

export default new Observer();
