import { IMAGE_ORIGINAL_URL } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const getImageSize = (width) => {
  if (!width) return IMAGE_ORIGINAL_URL;

  const selectedSize = [160, 400, 800, 1500].find((size) => size / width >= 2);

  return selectedSize ? `image${selectedSize}pxUrl` : IMAGE_ORIGINAL_URL;
};
