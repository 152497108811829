import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import GlideSlider from './GlideSlider';

class Slider extends WidgetWrapper {
  init = () => {
    const elSliders = dom.getCollection(this.selector);

    elSliders.forEach((element) => {
      const { widgetId = null, settings } = element.dataset;
      const options = JSON.parse(settings);

      if (!widgetId) return;

      GlideSlider(widgetId, element, options);
    });
  };
}

export default Slider;
