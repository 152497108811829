import isString from 'lodash/isString';

import { GET_PRODUCT_PAGE_REGEX } from '../constants';

const isProductPage = (href, storeUrl) => {
  if (!isString(href)) return false;

  return GET_PRODUCT_PAGE_REGEX(storeUrl).test(href);
};

export default isProductPage;
