import isEmpty from 'lodash/isEmpty';
import copy from 'copy-to-clipboard';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import openNewWindow from '../../helpers/openNewWindow';
import getSharedLinkByType from '../../helpers/getSharedLinkByType';
import browser from '../../helpers/browser';

import {
  checkIsContainerEnough,
  sumWidth,
  splitToRowsEvenly,
} from './utils';

import {
  CENTER,
  COLLAPSED,
  COLLAPSED_ALL,
  RIGHT,
  SHARE_BUTTON_CLASS,
  SHARE_EDIT_CLASS,
  SHARE_ICON_CLASS,
  SHARE_ROW_CLASS,
  SHARE_TEXT_CLASS,
  SPACE_FACTOR,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  MIN_ICON_WIDTH,
  SHARE_BOX_CLASS,
} from './constants';

class SharingButtonWidget extends WidgetWrapper {
  init = () => {
    this.buttonsFullWidth = [];
    this.buttonsCollapsedWidth = [];
    this.sumButtonsWidth = 0;
    this.sumButtonsCollapsedWidth = 0;
    this.elButtons = dom.getCollection(SHARE_BUTTON_CLASS, this.selector);
    this.buttonsLength = isEmpty(this.elButtons) ? 0 : this.elButtons.length;
    this.elShareRow = dom.getElement(SHARE_ROW_CLASS, this.selector);
    this.isCollapsedRight = dom.hasClass(this.selector, RIGHT);
    this.isCollapsedCenter = dom.hasClass(this.selector, CENTER);

    this.updateButtonsWidth();

    if (!checkIsContainerEnough(this.sumButtonsCollapsedWidth, this.selector)) {
      this.collapse(this.elButtons.length);
      this.updateButtonsWidth();
    }

    this.elButtons.forEach((button) => dom.on(
      button,
      'click',
      this.handleButtonClick(dom.getElement('a', button)),
    ));

    if (this.isCollapsedCenter) dom.addClass(this.elShareRow, COLLAPSED_ALL);

    subscribeToDeviceChanging(this.selector, () => this.updateButtonsWidth());
  };

  updateButtonsWidth = () => {
    // Need for container size calculating
    if (browser.isIe()) dom.updateStyle(this.elShareRow, { display: 'block' });

    const buttonsFullWidth = [];
    const buttonsCollapsedWidth = [];

    for (let i = 0; i < this.buttonsLength; i += 1) {
      const button = this.elButtons[i];

      const elContent = dom.getElement(SHARE_TEXT_CLASS, button);
      const elTextNode = dom.getElement(SHARE_EDIT_CLASS, button);
      const elIcon = dom.getElement(SHARE_ICON_CLASS, button);
      const elBox = dom.getElement(SHARE_BOX_CLASS, button);

      const buttonWidth = dom.getElementWidth(button);
      const iconElWidth = dom.getElementWidth(elIcon);
      const iconWidth = iconElWidth < MIN_ICON_WIDTH
        ? MIN_ICON_WIDTH
        : iconElWidth;
      const textWidth = dom.getElementWidth(elTextNode);
      const contentWidth = dom.getElementWidth(elContent);
      const boxWidth = dom.getElementWidth(elBox);

      const isCollapsed = dom.hasClass(button, COLLAPSED);

      let containerFullWidth = isCollapsed
        ? buttonWidth - iconWidth + (iconWidth * SPACE_FACTOR) + textWidth
        : buttonWidth - contentWidth + (iconWidth * SPACE_FACTOR) + textWidth;

      let containerCollapsedWidth = buttonWidth - boxWidth + iconWidth;

      if (containerCollapsedWidth < iconWidth) {
        containerCollapsedWidth = buttonWidth;
        containerFullWidth = buttonWidth - iconWidth + (iconWidth * SPACE_FACTOR) + textWidth;
      }

      buttonsFullWidth.push(containerFullWidth);
      buttonsCollapsedWidth.push(containerCollapsedWidth);
    }

    this.buttonsFullWidth = buttonsFullWidth;
    this.buttonsCollapsedWidth = buttonsCollapsedWidth;
    this.sumButtonsWidth = sumWidth(buttonsFullWidth);
    this.sumButtonsCollapsedWidth = sumWidth(buttonsCollapsedWidth);

    if (browser.isIe()) this.elShareRow.style.removeProperty('display');
  };

  resize = () => {
    if (isEmpty(this.elButtons)
      || isEmpty(this.buttonsFullWidth)
      || isEmpty(this.buttonsCollapsedWidth)
      || this.checkIsSplitted()) return;

    const parentWidth = dom.getElementWidth(this.selector);
    let collapsedCount = 0;
    let { sumButtonsWidth } = this;

    if (sumButtonsWidth <= parentWidth) {
      this.unCollapseAll();
      return;
    }

    if (this.isCollapsedCenter) {
      this.collapse(this.buttonsLength);
      return;
    }

    do {
      const index = this.isCollapsedRight
        ? this.buttonsLength - collapsedCount - 1
        : collapsedCount;
      const fullWidth = this.buttonsFullWidth[index];
      const collapsedWidth = this.buttonsCollapsedWidth[index];

      sumButtonsWidth = sumButtonsWidth
        - fullWidth
        + collapsedWidth;
      collapsedCount += 1;
    } while (sumButtonsWidth > parentWidth && collapsedCount <= this.buttonsLength);

    if (collapsedCount > this.buttonsLength) collapsedCount = this.buttonsLength;

    this.collapse(collapsedCount);
  };

  collapse = (collapsedCount = 0) => {
    if (isEmpty(this.elButtons)) return;

    for (let i = 0; i <= this.buttonsLength; i += 1) {
      const index = this.isCollapsedRight ? i : this.buttonsLength - i - 1;
      const currentButton = this.elButtons[index];

      if (collapsedCount >= this.buttonsLength - i) {
        dom.addClass(currentButton, COLLAPSED);
      } else {
        dom.removeClass(currentButton, COLLAPSED);
      }
    }

    const elButtonsWrapper = dom.getElement(SHARE_ROW_CLASS, this.selector);

    if (collapsedCount === this.buttonsLength) {
      dom.addClass(elButtonsWrapper, COLLAPSED_ALL);
    } else if (!this.isCollapsedCenter) {
      dom.removeClass(elButtonsWrapper, COLLAPSED_ALL);
    }
  };

  checkIsSplitted = () => dom
    .getCollection(SHARE_ROW_CLASS, this.selector)
    .length > 1;

  split = () => {
    if (isEmpty(this.elButtons)
      || isEmpty(this.buttonsCollapsedWidth)) return;

    if (checkIsContainerEnough(this.sumButtonsCollapsedWidth, this.selector)) {
      this.unsplit();
      return;
    }

    let splitCount = 0;
    let currentSum = 0;
    let rows = [];

    if (
      checkIsContainerEnough(this.sumButtonsCollapsedWidth, this.selector)
      && this.checkIsSplitted()
    ) {
      rows = [this.elButtons.length];
    } else {
      splitToRowsEvenly(this.buttonsCollapsedWidth, rows, this.selector);
    }

    rows.sort();

    const splittedArray = rows.reduce((acc, curr) => {
      const buttons = Array.from(this.elButtons);

      acc[splitCount] = buttons.slice(currentSum, currentSum + curr);
      splitCount += 1;
      currentSum += curr;

      return acc;
    }, []);

    if (splittedArray.length < 1) return;

    this.changeRowElToSplitted(splittedArray);
  }

  unsplit = () => {
    if (!this.checkIsSplitted()) return;

    this.changeRowElToUnsplitted();
    this.resize();
  }

  changeRowElToUnsplitted = () => {
    const row = dom.createElement('nav');
    dom.addClass(row, 'share__row');

    for (let i = 0; i <= this.elButtons.length - 1; i += 1) {
      row.appendChild(this.elButtons[i]);
    }

    this.clearStructure();
    this.selector.appendChild(row);
  }

  changeRowElToSplitted = (splittedArray) => {
    if (isEmpty(splittedArray)) return;

    this.clearStructure();

    for (let i = 0; i <= splittedArray.length - 1; i += 1) {
      const splitedRow = dom.createElement('nav');

      dom.addClass(splitedRow, 'share__row');
      dom.addClass(splitedRow, COLLAPSED_ALL);

      for (let j = 0; j <= splittedArray[i].length - 1; j += 1) {
        dom.addClass(splittedArray[i][j], COLLAPSED);
        splitedRow.appendChild(splittedArray[i][j]);
      }

      this.selector.appendChild(splitedRow);
    }
  }

  clearStructure = () => {
    const rows = dom.getCollection(SHARE_ROW_CLASS, this.selector);

    if (!rows || rows.length === 0) return;

    Array.from(rows).forEach((row) => {
      dom.removeElement(row);
    });
  }

  unCollapseAll = () => {
    if (isEmpty(this.elButtons)) return;

    for (let i = 0; i < this.buttonsLength; i += 1) {
      const currentButton = this.elButtons[i];

      dom.removeClass(currentButton, COLLAPSED);
    }
  };

  handleButtonClick = (elButton) => (e) => {
    const {
      widgetInWindow = true,
      widgetLinkType,
    } = elButton.dataset;

    if (widgetLinkType === 'copylink') {
      e.preventDefault();
      copy(dom.window.location.href);
      return;
    }

    const getLink = getSharedLinkByType(widgetLinkType);
    const url = getLink({ href: dom.window.location.href });

    elButton.setAttribute('href', '#');

    if (!url || !widgetInWindow || widgetInWindow === 'false') return;

    e.preventDefault();
    openNewWindow(url, '', WINDOW_WIDTH, WINDOW_HEIGHT);
  };
}

export default SharingButtonWidget;
