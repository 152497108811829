import lozad from 'lozad';
import isEmpty from 'lodash/isEmpty';

import dom from '../../wrapper/DomWrapper';
import browser from '../../helpers/browser';
import { doIEObjectFitPolyfill } from '../../polyfills';
import { photoErrorHandler } from '../Image/ErrorHandlers';

const isIE = browser.isIe();

export const lazy = lozad('.lazy', {
  loaded(el) {
    if (isIE && el.tagName === 'IMG') setTimeout(() => doIEObjectFitPolyfill(el), 0);

    if (el.tagName !== 'PICTURE') return;

    const image = dom.getElement('img', el);

    if (!image) return;

    const classes = el.getAttribute('data-classnames').split(' ');
    const alt = el.getAttribute('data-alt');
    const title = el.getAttribute('data-title');

    if (!isEmpty(classes)) classes.forEach((className) => dom.addClass(image, className));

    if (alt) image.setAttribute('alt', alt);

    if (title) image.setAttribute('title', title);

    photoErrorHandler();
  },
});

export default (el) => lazy.observe(el);
