import template from 'lodash/template';

class ItemsRenderer {
  constructor(tpl) {
    if (tpl) {
      this.compiled = template(tpl.innerHTML, {
        interpolate: /{{=([\s\S]+?)}}/g,
        evaluate: /{{([\s\S]+?)}}/g,
      });
      tpl.parentNode.removeChild(tpl);
    }
  }

  render = (data) => {
    if (!this.compiled) return null;

    return this.compiled(data);
  };
}

export default ItemsRenderer;
