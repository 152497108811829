import isEmpty from 'lodash/isEmpty';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

import ecommerce from '../../ecommerce';

import SingleProduct from './SingleProduct';
import ItemsRenderer from '../ItemsView/renderer';
import { getLabelClass } from '../ItemsView/modifiers/utils';
import {
  ASPECT_RATIO,
  BUTTON_LINE_HEIGHT,
  BUTTON_PADDING_VERTICAL,
  DISPLAY_ELEMENTS,
  PHOTO_MARGIN_BOTTOM,
  PRICE_MARGIN_BOTTOM,
  SQUARE_1,
  TEXT_MARGIN_BOTTOM,
} from '../../../components/Ecommerce/constants';
import { getImageSize } from './utils';
import ieChangeCssVar from '../../helpers/ieChangeCssVar';

const parseRootFloat = (cssVar) => {
  const varStr = dom.getCssVar(cssVar);
  const varFloat = parseFloat(varStr);
  return Number.isNaN(varFloat) ? 0 : varFloat;
};

export function getProductMinHeight(
  photoWidth = 0,
  display = DISPLAY_ELEMENTS,
  aspectRatio = SQUARE_1,
) {
  const buttonFontSize = parseRootFloat('--btn_primary_fontSize');
  const priceFontSize = parseRootFloat('--title_subHeading_fontSize');
  const priceLineHeight = parseRootFloat('--title_subHeading_lineHeight');
  const textFontSize = parseRootFloat('--text_fontSize');
  const textLineHeight = parseRootFloat('--text_lineHeight');

  const textHeight = TEXT_MARGIN_BOTTOM + textFontSize * textLineHeight;

  const heights = {
    photo: PHOTO_MARGIN_BOTTOM + photoWidth / aspectRatio,
    button: BUTTON_PADDING_VERTICAL + buttonFontSize * BUTTON_LINE_HEIGHT,
    price: PRICE_MARGIN_BOTTOM + priceFontSize * priceLineHeight,
    name: textHeight,
    sku: textHeight,
  };

  return display.reduce((height, key) => height + (heights[key] || 0), 0);
}

class ProductsWidget extends WidgetWrapper {
  init = (parent = dom.document) => {
    const elProductsList = dom.getCollection(this.selector, parent);

    if (isEmpty(elProductsList)) return;

    elProductsList.forEach((elProductWrapper = {}, index) => {
      const { widgetId = null, settings } = elProductWrapper.dataset;

      if (widgetId && settings) {
        const {
          productId, hash, display, aspectRatio = SQUARE_1,
        } = JSON.parse(settings);

        this.initProduct(elProductWrapper, productId, hash, display, aspectRatio, index);
      }
    });
  };

  initProduct = (elProductWrapper, productId, hash, display, aspectRatio, index) => {
    const elProductTemplate = dom.getElement(`#product-template-${hash}`);
    const elProductRetryTemplate = dom.getElement(`#product-retry-${hash}`);
    if (!elProductTemplate) return;

    const renderer = new ItemsRenderer(elProductTemplate);
    const retryRenderer = elProductRetryTemplate ? new ItemsRenderer(elProductRetryTemplate) : null;

    const run = () => {
      dom.addHtml(elProductWrapper, '');
      dom.addClass(elProductWrapper, 'spinner');
      const widgetWidth = dom.getElementWidth(elProductWrapper);
      const minHeight = getProductMinHeight(
        widgetWidth,
        Array.isArray(display) && display.length > 0 ? display : DISPLAY_ELEMENTS,
        ASPECT_RATIO[aspectRatio],
      );
      dom.updateStyle(elProductWrapper, { minHeight: `${minHeight}px` });
      ecommerce.provider.getProduct(productId)
        .then((product) => {
          dom.removeClass(elProductWrapper, 'spinner');
          dom.updateStyle(elProductWrapper, { minHeight: null });
          dom.addHtml(elProductWrapper, renderer.render({
            product,
            withLabel: getLabelClass(product),
            withGallery: product.images.length > 1,
            imageSize: getImageSize(widgetWidth),
          }));
          (new SingleProduct(dom.getElement('.product', elProductWrapper), product)).init(index);
        })
        .catch(() => {
          dom.removeClass(elProductWrapper, 'spinner');
          if (retryRenderer) {
            dom.addHtml(
              elProductWrapper,
              retryRenderer.render({ items: [1], itemClassName: 'gallery-item' }),
            );
            dom.on(dom.getElement('._try-btn', elProductWrapper), 'click', run);
          } else {
            dom.addHtml(elProductWrapper, '');
          }
        })
        .finally(() => {
          ieChangeCssVar();
        });
    };

    run();
  };
}

export default ProductsWidget;
