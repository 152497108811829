import isString from 'lodash/isString';
import { CSS_VAR_KEY_REGEX, PRECOLOR_REGEX_STRICT } from '../constants';

/**
 * @param {string} value
 * @return {boolean}
 */
export function isCssVar(value) {
  return isString(value) && CSS_VAR_KEY_REGEX.test(value);
}

/**
 * @param {string} source
 * @param {RegExp} regExp
 * @return {string | null}
 */
export function matchOnlyKey(source, regExp) {
  const matchedKeys = source.match(regExp);

  return matchedKeys && matchedKeys[0];
}

/**
 * @param {string} value
 * @return {boolean}
 */
export function isPreColor(value) {
  return PRECOLOR_REGEX_STRICT.test(value);
}

/**
 * @param {string} value
 * @return {string}
 */
export function toRGB(value) {
  return isPreColor(value) ? `rgb(${value})` : value;
}
