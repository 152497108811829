import { DEVICES, getDeviceSizes, QUAD } from '../../device';
import dom from '../wrapper/DomWrapper';
import { retinaMediaQuery } from './constants';

const { navigator: { platform, userAgent, vendor } } = dom.window;

/**
 * Function for get device type.
 * @param {number} width
 * @param {boolean} note
 * @param {boolean} fix
 * @return {string} type of device
 */
export const getDeviceType = (
  width = dom.window.innerWidth,
  note = false,
  fix = true,
) => {
  const sizes = getDeviceSizes(note, fix);

  // eslint-disable-next-line no-restricted-syntax
  for (const device in sizes) {
    if (Object.prototype.hasOwnProperty.call(sizes, device)) {
      const [min, max] = sizes[device];

      if (width >= min && width <= max) return device;
    }
  }

  return QUAD;
};

export default {
  isChrome: () => /Chrome/.test(userAgent) && /Google Inc/.test(vendor),
  isSafari: () => /safari/i.test(userAgent) && !/chrome/i.test(userAgent),
  isIe: () => userAgent.indexOf('Trident') !== -1,
  isEdge: () => userAgent.indexOf('Edge') !== -1,
  isMac: () => platform.match(/(Mac|iPhone|iPod|iPad)/i),
  isIos: () => platform.match(/(iPhone|iPod|iPad)/i),
  isRetina: () => dom.window.devicePixelRatio > 1
    || dom.window.matchMedia(retinaMediaQuery).matches,
  isMobile: () => /mobile/i.test(userAgent) || userAgent.match(/Android|webOS|Blackberry/i),
  isMobileDevice: () => [DEVICES.PHONE, DEVICES.TABLET].includes(getDeviceType()),
  isPhoneDevice: () => DEVICES.PHONE === getDeviceType(),
  isInFrame: dom.window.parent.self !== dom.window.self, // true - in iframe
};
