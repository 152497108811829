import EcwidProvider from './infrastructure/ecwidProvider';

class Ecommerce {
  constructor(providerType) {
    switch (providerType) {
      case 'ECWID':
        this.provider = new EcwidProvider();
        break;
      default:
        this.provider = new EcwidProvider();
    }
  }
}

export default new Ecommerce();
