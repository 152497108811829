export const GRID_SIZE = 12;
export const DEVICE_TYPES = ['tablet', 'phone'];
export const DEVICE_GRID = {
  4: {
    tablet: 6,
    mobile: 6,
    phone: 12,
  },
  3: {
    tablet: 6,
    mobile: 6,
    phone: 12,
  },
  2: {
    tablet: 3,
    mobile: 3,
    phone: 6,
  },
};
export const EMPTY_PROFILE = {
  text: 'sh.wf.instagram_empty_err_text',
  sub: 'sh.wf.instagram_empty_err_sub',
};
export const EXIST_PROFILE = {
  text: 'sh.wf.instagram_exist_err_text',
  sub: 'sh.wf.instagram_exist_err_sub',
};
