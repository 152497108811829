import memoize from 'lodash/memoize';
import getStateValue from '../getStateValue';

/**
 * Function for get Reseller settings.
 * @param {string} resellerId
 * @return {Object}
 */
const getResellerData = memoize(async (resellerId) => {
  if (!resellerId) return Promise.reject(new Error('reseller not found'));

  const staticHost = getStateValue('apiUrls.staticService');
  const request = `${staticHost}/resellers-settings/${resellerId}.json`;
  const response = await fetch(request, { method: 'GET' });
  return response.json();
});

export default getResellerData;
