import isString from 'lodash/isString';

import { GET_CART_PAGE_REGEX } from '../constants';

const isCartPage = (href, storeUrl) => {
  if (!isString(href)) return false;

  return GET_CART_PAGE_REGEX(storeUrl).test(href);
};

export default isCartPage;
