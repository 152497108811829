import dom from '../../../wrapper/DomWrapper';

import defaultImage from '../utils';
import errorHandler from './errorHandler';

const galleryErrorHandler = () => {
  const selectorName = '.gallery-wrap ._error-wrap';
  const onError = (elImage, elParent) => {
    // eslint-disable-next-line no-param-reassign
    elImage.src = defaultImage;
    dom.addClass(elParent, 'picture-wrap_unavailable');
    dom.addClass(elParent, 'picture-wrap_unavailable_hide');
  };

  errorHandler(selectorName, onError);
};

export default galleryErrorHandler;
