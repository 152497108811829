import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import dom from '../../../wrapper/DomWrapper';

import openNewWindow from '../../../helpers/openNewWindow';

import { BLOG_SOCIAL_BUTTON, WINDOW_HEIGHT, WINDOW_WIDTH } from './constants';
import getSharedLinkByType from '../../../helpers/getSharedLinkByType';

class SocialButton extends WidgetWrapper {
  init = () => {
    this.elButtons = dom.getCollection(`.${BLOG_SOCIAL_BUTTON}`, this.selector);

    this.elButtons.forEach((button) => dom.on(
      button,
      'click',
      this.handleButtonClick(button),
    ));
  };

  handleButtonClick = (button) => (e) => {
    const {
      widgetInWindow = true,
      widgetLinkType,
    } = button.dataset;

    const getLink = getSharedLinkByType(widgetLinkType);
    const url = getLink({ href: dom.window.location.href });

    button.setAttribute('href', url);

    if (!url || !widgetInWindow || widgetInWindow === 'false') return;

    e.preventDefault();
    openNewWindow(url, '', WINDOW_WIDTH, WINDOW_HEIGHT);
  };
}

export default SocialButton;
