import getApiUrl from './getApiUrl';

export default (payload) => {
  const apiUrl = getApiUrl();

  return fetch(`${apiUrl}/api`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};
