import get from 'lodash/get';
import dom from '../../../wrapper/DomWrapper';

import defaultImage from '../utils';
import errorHandler from './errorHandler';

const photoErrorHandler = () => {
  const selectorName = '.picture-wrap';
  const onError = (elImage, elParent) => {
    const previous = get(elImage, ['previousSibling', 'previousSibling']);

    if (!previous) return;

    previous.srcset = defaultImage;
    dom.addClass(elParent, 'picture-wrap_unavailable');
  };

  errorHandler(selectorName, onError);
};

export default photoErrorHandler;
