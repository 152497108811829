const getProductId = (href) => {
  const numericIdRegex = /[0-9]+$/gm;

  if (href.search(numericIdRegex) === -1) return null;

  const productId = href.match(numericIdRegex)[0];

  return parseInt(productId, 10) || null;
};

export default getProductId;
