import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export default class ObserverClass {
  constructor(key) {
    this.key = key;
    this.observe = new Map();
    this.subscribers = new Map();
  }

  /**
   * @param {string} key
   * @returns {*}
   */
  get(key) {
    return this.observe.get(key);
  }

  /**
   * @param {string|Object} key
   * @param {*} value
   * @param {HTMLElement | Element} except
   */
  set(key, value, except) {
    if (isObject(key)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const name in key) {
        if (Object.prototype.hasOwnProperty.call(key, name)) {
          this.set(name, key[name], except);
        }
      }
    } else {
      this.observe.set(key, value);
      this.subscribers.forEach((updater, component) => {
        if ((!except || component !== except) && updater) {
          updater(key, value, this.toJS.bind(this));
        }
      });
    }

    return this;
  }

  /**
   * @param {HTMLElement | Element} component
   * @param {function} func
   * @param {Object[]|string} keys
   * @returns {Object}
   */
  subscribe(component, func, keys = null) {
    // eslint-disable-next-line no-console
    if (isString(component)) console.warn('Component shouldnt be string', component);

    if (!this.subscribers.has(component)) {
      let action;

      if (isArray(keys)) {
        action = (key, value) => keys.includes(key) && func(key, value);
      } else if (isString(keys)) {
        action = (key, value) => keys === key && func(value);
      } else {
        action = func;
      }

      this.subscribers.set(component, action);
    }
    return this;
  }

  toJS() {
    return this.getPlainObject();
  }
}
