export const CATEGORY = 'CATEGORY';
export const PRODUCT = 'PRODUCT';
export const CART = 'CART';
export const ORDERS = 'ORDERS';

export const PRODUCT_TYPE = {
  CATEGORY,
  PRODUCT,
  CART,
  ORDERS,
};

export const PRODUCT_OPTION_TYPES = {
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
};

export const PRICE_MODIFIER_TYPES = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENT: 'PERCENT',
};
