import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import { DARK_ICON_CLASSNAME, SOC_ICON_SELECTOR } from './constants';
import { isLight } from '../../helpers/color';

class SocialButtons extends WidgetWrapper {
    init = () => {
      const icons = dom.getCollection(SOC_ICON_SELECTOR, dom.getElement(this.selector));

      if (!icons) return;

      icons.forEach((icon) => {
        const iconColor = dom.window.getComputedStyle(icon).color || '#000';

        if (isLight(iconColor)) dom.addClass(icon, DARK_ICON_CLASSNAME);
      });
    }
}

export default SocialButtons;
