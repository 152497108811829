import ProviderWrapper from './Wrapper';
import dom from '../../../wrapper/DomWrapper';

class VideoYouTube extends ProviderWrapper {
  init() {
    const {
      id,
      autoplay,
      fs,
      color,
      removeControls,
      isBackground,
    } = this.dataParams;

    this.renderVideoOnPage();
    this.player = this.connectVideoDefault();
    this.addPreviewImage(this.player);

    this.player.source = {
      type: 'video',
      sources: [
        {
          src: id,
          provider: 'youtube',
        },
      ],
    };

    this.player.config.youtube = {
      autopause: 0,
      color,
      controls: removeControls ? 0 : 1,
      disablekb: 1,
      fs,
      iv_load_policy: 3,
      rel: 0,
      showinfo: 0,
      playlist: 0,
      resetOnEnd: 1,
      mute: isBackground ? 1 : autoplay,
      origin: dom.window.location.origin,
    };

    this.player.elements.poster.style.display = 'none';

    if (isBackground) {
      this.player.config.youtube.controls = 0;
      this.player.config.youtube.cc_load_policy = 0;
      this.player.config.youtube.fs = 0;
      this.player.config.youtube.iv_load_policy = 3;
      this.player.config.youtube.autoplay = 1;
      this.player.config.youtube.modestbranding = 1;
      this.player.config.youtube.playlist = 0;
    }
  }
}

export default VideoYouTube;
