import {
  scrollToHashFromUrl,
  getAnchorsList,
  setSectionIdToExternalAnchor,
  setAnchorToCurrentPage,
} from './utils';

export default () => {
  scrollToHashFromUrl();

  const { currentPageAnchors, externalPageAnchors } = getAnchorsList();

  setSectionIdToExternalAnchor(externalPageAnchors);
  setAnchorToCurrentPage(currentPageAnchors);
};
