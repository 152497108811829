import dom from '../../../wrapper/DomWrapper';

import defaultImage from '../utils';
import errorHandler from './errorHandler';

const slideBgErrorHandler = () => {
  const selectorName = '.slide_bg ._error-wrap';
  const onError = (elImage, elParent) => {
    // eslint-disable-next-line no-param-reassign
    elImage.src = defaultImage;
    dom.addClass(elParent, 'picture-wrap_unavailable');
  };

  errorHandler(selectorName, onError);
};

export default slideBgErrorHandler;
