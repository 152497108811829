import createRootGetter from './createRootGetter';
import recursiveVarFind from './recursiveVarFind';

/**
 * @param {string} name
 * @return {*}
 */
function getRoot(name) {
  const getter = createRootGetter((key) => recursiveVarFind(key));

  return !name ? name : getter(name);
}

export default getRoot;
