import dom from '../../wrapper/DomWrapper';

import Pagination from './Pagination';

class EndlessScroll extends Pagination {
  firstConfirmed = false;

  confirmRequired = false;

  confirmEvery = 4;

  loadAt = 250;

  constructor(options, container, renderer) {
    super(options, container, renderer);

    this.options = options;

    if (this.options.confirmEvery) this.confirmEvery = this.options.confirmEvery;

    this.renderer = renderer;
    this.container = container;
  }

  init = () => {
    const { itemsCount: limit } = this.options;
    this.fireEvent(Pagination.events.onInit, { offset: 0, limit });
    dom.on(dom.window, 'scroll', this.calculate);
  };

  calculate = () => {
    if (!this.pager
      || this.state.isLoading
      || this.confirmRequired
    ) return;

    const pagerRect = this.pager.getBoundingClientRect();

    if (pagerRect.top - dom.window.innerHeight > this.loadAt) return;

    this.goNextPage();
  };

  onDidMount = (pager) => {
    this.pager = pager;
    this.calculate();
  };

  goNextPage = () => {
    const { pageNum } = this.pager.dataset;

    if (!+pageNum) return;

    this.setPage(pageNum);
    this.render();
  };

  getTemplateData = ({ offset, limit, total }) => {
    const { isLoading } = this.state;
    const currentPage = Math.ceil(offset / limit);
    const pageCount = Math.ceil(total / limit);
    const nextPage = currentPage + 1 < pageCount ? currentPage + 1 : false;

    this.currentPage = currentPage;
    this.confirmRequired = !this.firstConfirmed
      || (this.confirmEvery && (currentPage + 1) % this.confirmEvery === 0);

    return {
      nextPage,
      visible: !!nextPage,
      currentPage,
      pageCount,
      confirmRequired: this.confirmRequired,
      isLoading,
    };
  };

  attachListeners = (pager) => {
    const button = dom.getElement('a.btn', pager);

    if (button) {
      dom.on(button, 'click', () => {
        this.firstConfirmed = true;
        this.confirmRequired = false;
        this.goNextPage();
      });
    }

    return pager;
  };
}

export default EndlessScroll;
