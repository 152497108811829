import get from 'lodash/get';
import dom from '../../../wrapper/DomWrapper';
import ProviderWrapper from './Wrapper';

class VideoDailymotion extends ProviderWrapper {
  init() {
    this.player = this.connectDailymotionPlayer();
    this.addPreviewImage(this.player);
  }

  connectDailymotionPlayer() {
    const {
      id, hash, autoplay, fs: controls, isBackground,
    } = this.dataParams;

    const elVideoContainer = dom.getElement(`#v-${hash}`, this.video);
    this.player = DM.player(elVideoContainer, {
      video: id,
      width: '100%',
      height: '100%',
      params: {
        autoplay: !!autoplay,
        mute: isBackground ? true : !!autoplay,
        controls: controls || autoplay,
        queue: false,
        'sharing-enable': false,
      },
    });

    if (isBackground) {
      dom.on(this.player, 'playback_ready', this.onPlayerPlayBack);
    }

    dom.on(this.player, 'playing', this.onPlayerPlaying);

    dom.on(this.player, 'video_end', this.onPlayerEnded);

    return this.player;
  }

  onPlayerPlayBack = () => {
    this.resizeVideo();
  }

  onPlayerPlaying = () => {
    const isBackground = get(this, ['dataParams', 'isBackground'], false);
    const autoplay = get(this, ['dataParams', 'autoplay'], false);

    this.hidePreview();

    if (!isBackground && !autoplay) return;

    const timeout = this.getLoopTimeoutTime();

    this.loopTimeoutId = setTimeout(this.playSingleVideo, timeout);
  }

  playSingleVideo = () => {
    const startTime = get(this, ['dataParams', 'videoParams', 't']);
    const startTimeInSec = this.getTimeCode(startTime);

    this.player.seek(startTimeInSec || 0);
  }
}

export default VideoDailymotion;
