import { setBodyHasScroll } from '../../helpers/setBodyClasses';
import dom from '../../wrapper/DomWrapper';
import { getAnchorTargetObj, sendMessage } from './utils';

const TemplatePreview = () => {
  const header = dom.getElement('.main__header');
  const isFixedHeader = dom.hasClass(header, 'header_fixed');

  dom.window.onbeforeunload = () => {
    sendMessage({
      type: 'clearHeight',
      height: null,
    });

    sendMessage({
      type: 'height',
      height: null,
      cycle: 'unload',
    });
  };

  dom.window.addEventListener('click', (e) => {
    const targetObj = getAnchorTargetObj(e.target);

    sendMessage({
      type: 'iframeAnchorClick',
      ...targetObj,
    });
  });

  dom.window.addEventListener('message', (message) => {
    const {
      data: { type },
    } = message;

    switch (type) {
      case 'scrolling':
        sendMessage(
          {
            type: 'height',
            height: dom.document.body.clientHeight,
            isFixedHeader,
          },
          message.origin,
        );
        break;
      case 'iframeLoaded':
        setTimeout(() => {
          setBodyHasScroll();
          sendMessage({
            type: 'height',
            height: dom.document.body.clientHeight,
          });
        }, 1500);
        break;
      case 'onScroll':
        dom.window.scroll(0, message.data.scrollTop);
        break;
      default:
        break;
    }
  });
};

export default TemplatePreview;
